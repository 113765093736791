import React from 'react';
import styled from 'styled-components';
import VerificationLogo from '../assets/Logo_THFS.jpg';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
`;

const YesButton = styled.button`
  padding: 10px;
  font-size: 1em;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const NoButton = styled.button`
  padding: 10px;
  font-size: 1em;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const Logo = styled.img`
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
`;


const AgeVerification = ({ onVerify }) => {
  return (
    <ModalBackground>   
      <ModalContainer>
        <h2>Welcome to ToHighForSlots</h2>   
        <Logo src={VerificationLogo} alt="Logo" />
        <h2>18+ Entry Only</h2>
        <p>To enter this site, you must be at least 18 years old</p>
        <YesButton onClick={() => onVerify(true)}>I am 18+</YesButton>
        <NoButton onClick={() => onVerify(false)}>I am under 18</NoButton>
      </ModalContainer>
    </ModalBackground>
  );
};

export default AgeVerification;
