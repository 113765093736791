import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';

// Import icons
import bombeIcon from '../assets/Bombe_Icon.png';
import giftpfeilIcon from '../assets/Giftpfeil_Icon.png';
import damageMultiplierIcon from '../assets/DamageMultiplier_Icon.png';
import dollarIcon from '../assets/Dollar_Icon.png';

const SpinWheel = () => {
  const [rotation, setRotation] = useState(0);
  const [spinning, setSpinning] = useState(false);
  const [prize, setPrize] = useState(null);

  const icons = [
    { src: bombeIcon, alt: 'Bombe', prize: 'Bombe!' },
    { src: giftpfeilIcon, alt: 'Giftpfeil', prize: 'Giftpfeil!' },
    { src: damageMultiplierIcon, alt: 'Damage Multiplier', prize: 'Schadenmultiplikator!' },
    { src: dollarIcon, alt: 'Dollar', prize: 'RAW Cash!' }
  ];

  const spin = () => {
    if (spinning) return;
    setSpinning(true);
    setPrize(null);
    const newRotation = rotation + 360 * 10 + Math.floor(Math.random() * 360);
    setRotation(newRotation);
    setTimeout(() => {
      setSpinning(false);
      const winningIndex = Math.floor(((360 - (newRotation % 360)) % 360) / 90);
      setPrize(icons[winningIndex].prize);
    }, 8000);
  };

  return (
    <Container>
      <WheelContainer>
        <Wheel rotation={rotation} spinning={spinning} spinningRotation={rotation + 360 * 10}>
          {icons.map((icon, index) => (
            <Segment key={index} style={{ transform: `rotate(${index * 90}deg)` }}>
              <SegmentContent>
                <IconImage src={icon.src} alt={icon.alt} />
              </SegmentContent>
            </Segment>
          ))}
        </Wheel>
        <Arrow />
      </WheelContainer>
      <Button onClick={spin} disabled={spinning}>
        {spinning ? 'Dreht sich...' : 'Drehen'}
      </Button>
      {prize && <PrizeDisplay>Gewinn: {prize}</PrizeDisplay>}
    </Container>
  );
};

const spinAnimation = (start, end) => keyframes`
  0% {
    transform: rotate(${start}deg);
  }
  20% {
    transform: rotate(${start + (end - start) * 0.7}deg);
  }
  100% {
    transform: rotate(${end}deg);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
`;

const WheelContainer = styled.div`
  position: relative;
  width: 320px;
  height: 320px;
`;

const Wheel = styled.div`
  width: 300px;
  height: 300px;
  border: 10px solid #000;
  border-radius: 50%;
  position: relative;
  transition: transform 8s cubic-bezier(0.25, 0.1, 0.25, 1);
  transform: ${({ rotation }) => `rotate(${rotation}deg)`};
  ${({ spinning, rotation, spinningRotation }) =>
    spinning &&
    css`
      animation: ${spinAnimation(rotation, spinningRotation)} 8s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    `}

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid #000;
    border-radius: 50%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 2px solid #000;
  }
`;

const Segment = styled.div`
  width: 50%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform-origin: 0 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background-color: #000;
    transform: translateX(-50%);
  }
`;

const SegmentContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-45deg);
`;

const IconImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
`;

const Arrow = styled.div`
  position: absolute;
  top: -10px;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 40px solid red;
  transform: translateX(-50%);
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const PrizeDisplay = styled.div`
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #007BFF;
`;

export default SpinWheel;