import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
  import { Canvas, useFrame, useThree } from '@react-three/fiber';
  import { OrbitControls, Environment, PerspectiveCamera, Text } from '@react-three/drei';
  import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
  import * as THREE from 'three';
  import axios from 'axios';
  import styled from 'styled-components';
  import levelData from './Bossfight_Level.json';
  import { Modal, Box, TextField, Button as MuiButton } from '@mui/material';

  // Import avatar images
  import Avatar1 from '../assets/Avatar_1.png';
  import Avatar2 from '../assets/Avatar_2.png';
  import Avatar3 from '../assets/Avatar_3.png';
  import Avatar4 from '../assets/Avatar_4.png';
  import Avatar5 from '../assets/Avatar_5.png';
  import Avatar6 from '../assets/Avatar_6.png';
  import Avatar7 from '../assets/Avatar_7.png';
  import Avatar8 from '../assets/Avatar_8.png';
  import Avatar9 from '../assets/Avatar_9.png';

  // Import item icons
  import bombeIcon from '../assets/Bombe_Icon.png';
  import giftpfeilIcon from '../assets/Giftpfeil_Icon.png';
  import damageMultiplierIcon from '../assets/DamageMultiplier_Icon.png';

  const GameContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

const PlayerSection = styled.div`
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100vh; // Ensure it takes the full height
  box-sizing: border-box; // Include padding in the height calculation
`;

const GameSection = styled.div`
  flex: 0 0 70%;
  display: flex;
  flex-direction: column;
`;

const CanvasContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const RegistrationForm = styled.div`
  margin-bottom: 20px;
`;

const Input = styled.input`
  margin: 5px;
  padding: 5px;
  width: calc(100% - 10px);
`;

const Button = styled.button`
  margin: 5px;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

const ResetButton = styled.button`
  margin: 5px;
  padding: 5px 10px;
  background-color: #FF0000;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #AB0000;
  }
`;



const PlayerGridContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 10px; // Add some padding to prevent content from touching the scrollbar

  /* Customizing the scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
`;

const PlayerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 10px;
  padding-bottom: 20px; // Add some padding at the bottom for better scrolling experience
`;

const PlayerCard = styled.div`
  background-color: #E3E3E3;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  position: relative;

  &:hover {
    transform: translateY(-5px);
  }
`;

const RemoveButton = styled(Button)`

  z-index: 10;
`;


const PlayerHeader = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
`;

const DamageMultiplierContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
`;

const DamageMultiplierIcon = styled.img`
  width: 36px;
  height: 36px;
  z-index: 1;
`;

const DamageMultiplierValue = styled.div`
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
`;

const PlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
`;

const AvatarImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
`;

const PlayerName = styled.h3`
  margin: 0;
  font-size: 18px;
`;

const PlayerDetails = styled.p`
  margin: 2px 0;
  font-size: 14px;
  color: #666;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

const SpecialItems = styled.div`
  margin-top: 10px;
  font-size: 12px;
`;

const ItemList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 5px 0 0 0;
`;

const ItemIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
`;

const ItemListItem = styled.li`
  margin: 2px 0;
  display: flex;
  align-items: center;
`;

const ControlButtons = styled.div`
  padding: 10px;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 10px;
  background-color: #9B9B9B;
  border-radius: 5px;
  margin-top: 5px;
`;

const ProgressBarFill = styled.div`
  height: 100%;
  background-color: #4caf50;
  border-radius: 5px;
  width: ${props => props.percentage}%;
  transition: width 0.3s ease-in-out;
`;

const LevelExpContainer = styled.div`
  text-align: center;
  font-size: 12px;
  color: #666;
  width: 100%;
`;

  const Character = ({ action }) => {
  const ref = useRef();
  const [mixer] = useState(() => new THREE.AnimationMixer());
  const [idle, setIdle] = useState(null);
  const [chickenDance, setChickenDance] = useState(null);
  const [hipHop, setHipHop] = useState(null);
  const [hit, setHit] = useState(null);
  const [fallingDeath, setFallingDeath] = useState(null);
  const [lyingDeath, setLyingDeath] = useState(null);

  useEffect(() => {
    const loader = new FBXLoader();

    const loadAnimation = (file, setter) => {
      loader.load(`/${file}.fbx`, (fbx) => {
        setter(fbx.animations[0]);
        if (file === 'idle') {
          fbx.scale.set(0.01, 0.01, 0.01);
          ref.current.add(fbx);
        }
      }, undefined, (error) => {
        console.error(`Error loading ${file}.fbx:`, error);
      });
    };

    loadAnimation('idle', setIdle);
    loadAnimation('ChickenDance', setChickenDance);
    loadAnimation('HipHop', setHipHop);
    loadAnimation('hit', setHit);
    loadAnimation('FallingDeath', setFallingDeath);
    loadAnimation('LyingDeath', setLyingDeath);

    return () => mixer.stopAllAction();
  }, [mixer]);

  useEffect(() => {
    if (idle && chickenDance && hipHop && hit && fallingDeath && lyingDeath) {
      const idleAction = mixer.clipAction(idle, ref.current);
      const chickenDanceAction = mixer.clipAction(chickenDance, ref.current);
      const hipHopAction = mixer.clipAction(hipHop, ref.current);
      const hitAction = mixer.clipAction(hit, ref.current);
      const fallingDeathAction = mixer.clipAction(fallingDeath, ref.current);
      const lyingDeathAction = mixer.clipAction(lyingDeath, ref.current);

      mixer.stopAllAction();

      switch (action) {
        case 'chickenDance':
          chickenDanceAction.reset().play();
          chickenDanceAction.setLoop(THREE.LoopRepeat);
          break;
        case 'hipHop':
          hipHopAction.reset().play();
          hipHopAction.setLoop(THREE.LoopRepeat);
          break;
        case 'hit':
          hitAction.reset().play();
          hitAction.setLoop(THREE.LoopOnce);
          hitAction.clampWhenFinished = true;
          break;
        case 'fallingDeath':
          fallingDeathAction.reset().play();
          fallingDeathAction.setLoop(THREE.LoopOnce);
          fallingDeathAction.clampWhenFinished = true;
          break;
        case 'lyingDeath':
          lyingDeathAction.reset().play();
          lyingDeathAction.setLoop(THREE.LoopOnce);
          lyingDeathAction.clampWhenFinished = true;
          break;
        default:
          idleAction.reset().play();
      }
    }
  }, [action, idle, chickenDance, hipHop, hit, fallingDeath, lyingDeath, mixer]);

  useFrame((_, delta) => mixer.update(delta));

  return <group ref={ref} position={[0, -0.5, 0]} />;
};

const Lighting = () => {
  return (
    <>
      <ambientLight intensity={1.5} />
      <directionalLight
        position={[5, 5, 5]}
        intensity={1}
        castShadow
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
      />
      <pointLight position={[-5, 5, -5]} intensity={0.5} />
    </>
  );
};

const Healthbar = ({ health }) => {
  const width = 1;
  const height = 0.1;
  const depth = 0.05;

  const getColor = () => {
    if (health > 50) return 'green';
    if (health > 20) return 'orange';
    return 'red';
  };

  return (
    <group position={[0, 1.5, 0]}>
      <mesh>
        <boxGeometry args={[width, height, depth]} />
        <meshBasicMaterial color="gray" />
      </mesh>
      <mesh position={[-(width - width * health / 1000) / 2, 0, depth / 2 + 0.001]}>
        <boxGeometry args={[width * health / 1000, height, 0.01]} />
        <meshBasicMaterial color={getColor()} />
      </mesh>
    </group>
  );
};

const Scene = ({ action, timerSeconds, health }) => {
  console.log("Current Action:", action);
  console.log("Timer Seconds:", timerSeconds);

  return (
    <>
      <PerspectiveCamera makeDefault position={[-1, 0.5, 4]} fov={50} />
      <Lighting />
      <Character action={action} />
      <Healthbar health={health} />
      <Environment preset="studio" />
      <mesh rotation={[-Math.PI / 2, 0, 0]} position={[-1, -0.5, 0]} receiveShadow>
        <planeGeometry args={[10, 10]} />
        <shadowMaterial opacity={0.4} />
      </mesh>
      {action === 'hipHop' && <Timer seconds={timerSeconds} />}
    </>
  );
};

const Timer = ({ seconds }) => {
  return (
    <Text
      position={[0, 1.75, 0]} // Adjusted position to ensure visibility below the character
      fontSize={0.2}
      color="white"
      anchorX="center"
      anchorY="middle"
    >
      {`Let's get Readyyy ... ${seconds}`}
    </Text>
  );
};
  
  
  
  const StreamerView_Bossfight = () => {
    const [action, setAction] = useState('idle');
    const [timerSeconds, setTimerSeconds] = useState(30);
    const [health, setHealth] = useState(1000);
    const audioRef = useRef(null);
    const introAudioRef = useRef(null);
    const ouchAudioRefs = useRef([]);
    const ouchLongAudioRef = useRef(null);
    const timeoutRef = useRef(null);
    const intervalRef = useRef(null);
    const cycleCountRef = useRef(0);
    const wsRef = useRef(null);
    const [activePlayers, setActivePlayers] = useState([]);
    const [newPlayer, setNewPlayer] = useState({
      playerName: '',
      game: '',
      platform: '',
      avatar: '',
      specialItems: {
        Bomben: 0,
        Giftpfeile: 0,
        'Damage-Multiplier': 0
      }
    });
    const [registrationError, setRegistrationError] = useState('');
    const [playerHeader, setPlayerHeader] = useState("Registered Players:");
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [buyPrice, setBuyPrice] = useState('');
    const [result, setResult] = useState('');
  
    const avatars = [
      Avatar1, Avatar2, Avatar3, Avatar4, Avatar5, Avatar6, Avatar7, Avatar8, Avatar9
    ];
  
    const itemIcons = {
      Bomben: bombeIcon,
      Giftpfeile: giftpfeilIcon,
      'Damage-Multiplier': damageMultiplierIcon
    };
  
    const getRandomAvatar = useCallback(() => {
      const randomIndex = Math.floor(Math.random() * avatars.length);
      return avatars[randomIndex];
    }, [avatars]);



    const sendMessage = (action, duration) => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send(JSON.stringify({ action, duration }));
      }
    };

  
    useEffect(() => {
      const wsUrl = `ws://${process.env.REACT_APP_WS_URL}?clientType=Bossfight`;
      wsRef.current = new WebSocket(wsUrl);
  
      wsRef.current.onopen = () => {
        console.log('WebSocket connected');
      };
  
      wsRef.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        console.log('Received message:', message);
      };
  
      wsRef.current.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
  
      wsRef.current.onclose = (event) => {
        console.log('WebSocket disconnected:', event);
      };
  
      audioRef.current = new Audio('/ChickenDance.mp3');
      audioRef.current.load();
      introAudioRef.current = new Audio('/Intro.mp3');
      introAudioRef.current.load();
      ouchLongAudioRef.current = new Audio('/Ouch_Long_1.mp3');
      ouchLongAudioRef.current.load();
  
      ouchAudioRefs.current = [
        new Audio('/Ouch.mp3'),
        new Audio('/Ouch_2.mp3'),
        new Audio('/Ouch_3.mp3')
      ];
      ouchAudioRefs.current.forEach(audio => audio.load());
  
      fetchActivePlayers();
  
      return () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        if (intervalRef.current) clearInterval(intervalRef.current);
        
        if (wsRef.current) {
          wsRef.current.close();
        }
  
        const stopAudio = (audio) => {
          if (audio && typeof audio.pause === 'function') {
            audio.pause();
            audio.currentTime = 0;
          }
        };
  
        stopAudio(audioRef.current);
        stopAudio(introAudioRef.current);
        stopAudio(ouchLongAudioRef.current);
        ouchAudioRefs.current.forEach(stopAudio);
      };
    }, []);
  
    const handleChickenDance = () => {

  
      timeoutRef.current = setTimeout(() => {
        setAction('chickenDance');
        sendMessage('chickenDance');
      }, 350);
  
      timeoutRef.current = setTimeout(() => {
        setAction('idle');
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
      }, 5000);
    };
  
    const handleHipHop = () => {
      const cycleCount = 3;
      const cycleDuration = 10000; // 10 seconds per cycle
      const totalDuration = cycleCount * cycleDuration;
      
      setTimerSeconds(totalDuration / 1000);
      setAction('hipHop');
    
      // Send the message with total duration
      sendMessage('hipHop', totalDuration);
    
      // Send the message with total duration
      sendMessage({ action: 'hipHop', duration: totalDuration });
    
      let currentCycle = 0;
    
      const runCycle = () => {
        currentCycle++;
        
        if (currentCycle < cycleCount) {
          timeoutRef.current = setTimeout(runCycle, cycleDuration);
        } else {
          timeoutRef.current = setTimeout(() => {
            setAction('idle');
            sendMessage({ action: 'idle' });
            if (introAudioRef.current) {
              introAudioRef.current.pause();
              introAudioRef.current.currentTime = 0;
            }
          }, cycleDuration);
        }
      };
    
      runCycle();
    
      if (intervalRef.current) clearInterval(intervalRef.current);
      intervalRef.current = setInterval(() => {
        setTimerSeconds(prev => {
          if (prev > 0) return prev - 1;
          clearInterval(intervalRef.current);
          return 0;
        });
      }, 1000);
    };
  
    const handleHit = (damage) => {
      console.log(damage)
      const newHealth = Math.max(health - damage, 0);
      setHealth(newHealth);
  
      if (newHealth > 0) {
        setAction('hit');
        sendMessage('hit');
        const randomIndex = Math.floor(Math.random() * 3);
        const ouchAudio = ouchAudioRefs.current[randomIndex];
        if (ouchAudio) {
          ouchAudio.currentTime = 0;
          ouchAudio.play();
        }
  
        timeoutRef.current = setTimeout(() => {
          setAction('idle');
        }, 1000);
      } else {
        setAction('fallingDeath');
        timeoutRef.current = setTimeout(() => {
          setAction('lyingDeath');
        }, 2000);
      }
    };
  
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setNewPlayer(prev => ({ ...prev, [name]: value }));
    };
  
    const calculateExpPercentage = (level, exp) => {
      const currentLevelData = levelData.levels.find(l => l.level === level);
      const nextLevelData = levelData.levels.find(l => l.level === level + 1);
      
      if (!currentLevelData || !nextLevelData) return 0;
  
      const expForCurrentLevel = currentLevelData.expRequired;
      const expForNextLevel = nextLevelData.expRequired;
      const expNeededForNextLevel = expForNextLevel - expForCurrentLevel;
      const currentLevelProgress = exp - expForCurrentLevel;
  
      return Math.min((currentLevelProgress / expNeededForNextLevel) * 100, 100);
    };
  
    const handleRegisterPlayer = async () => {
      try {
        setRegistrationError('');
        const playerWithAvatar = {
          ...newPlayer,
          avatar: getRandomAvatar()
        };
        const response = await axios.post(`${process.env.REACT_APP_SERVER_ADDRESS}/api/registerBossfight`, playerWithAvatar);
        
        if (response.data.status === 'success') {
          setActivePlayers(prevPlayers => {
            const index = prevPlayers.findIndex(p => p.playerName === response.data.player.playerName);
            if (index !== -1) {
              const updatedPlayers = [...prevPlayers];
              updatedPlayers[index] = response.data.player;
              return updatedPlayers;
            } else {
              return [...prevPlayers, response.data.player];
            }
          });
  
          setNewPlayer({
            playerName: '',
            game: '',
            platform: '',
            avatar: ''
          });
        } else {
          setRegistrationError(response.data.message);
        }
      } catch (error) {
        console.error('Error registering player:', error);
        setRegistrationError(error.response?.data?.message || 'Error registering player. Please try again.');
      }
    };
  
    const fetchActivePlayers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_ADDRESS}/api/activeBossfightPlayers`);
        setActivePlayers(response.data);
      } catch (error) {
        console.error('Error fetching active players:', error);
      }
    };
  
    const handleStartNewBossfight = async () => {
      try {
        await axios.post(`${process.env.REACT_APP_SERVER_ADDRESS}/api/startBossfight`);
        fetchActivePlayers();
        setPlayerHeader("Active Players:");
      } catch (error) {
        console.error('Error starting new Bossfight:', error);
      }
    };
  
    const handleRemovePlayer = async (event, playerName) => {
      event.stopPropagation(); // Dies verhindert, dass das Klick-Event an die PlayerCard weitergeleitet wird
      try {
        await axios.delete(`${process.env.REACT_APP_SERVER_ADDRESS}/api/removeBossfightPlayer/${playerName}`);
        fetchActivePlayers();
      } catch (error) {
        console.error('Error removing player:', error);
      }
    };

    const handlePlayerClick = (player) => {
      setSelectedPlayer(player);
      setBuyPrice(player.buyPrice || '');
      setResult(player.result || '');
      setModalOpen(true);
    };

    const handleModalClose = () => {
      setModalOpen(false);
      setSelectedPlayer(null);
      setBuyPrice('');
      setResult('');
    };

    const handleSetPlayerData = async () => {
      if (selectedPlayer && (buyPrice || result)) {
        try {
          await axios.post(`${process.env.REACT_APP_SERVER_ADDRESS}/api/updatePlayerData`, {
            playerName: selectedPlayer.playerName,
            buyPrice: parseFloat(buyPrice),
            result: parseFloat(result)
          });
          fetchActivePlayers();
          handleModalClose();
  
          // Wait for 2 seconds before executing the hit
          setTimeout(() => {
            executeHit(selectedPlayer.playerName, parseFloat(result), parseFloat(buyPrice));
          }, 2000);
        } catch (error) {
          console.error('Error updating player data:', error);
        }
      }
    };
  
    const executeHit = (playerName, result, buyPrice) => {
      const player = activePlayers.find(p => p.playerName === playerName);
      if (player) {
        const damageMultiplier = player.specialItems['Damage-Multiplier'] || 1;
        const damage = (result / buyPrice) * damageMultiplier * 100;
        
        // Execute the hit
        handleHit(damage);
        
        // You may want to send this information to the server or update the UI
        console.log(`${playerName} hit the boss for ${damage.toFixed(2)} damage!`);
      }
    };
  
  
  
    return (
      <GameContainer>
        <PlayerSection>
          <ResetButton onClick={handleStartNewBossfight}>Reset Bossfight</ResetButton>
          <RegistrationForm>
            <h3>Register Player</h3>
            <Input
              type="text"
              name="playerName"
              value={newPlayer.playerName}
              onChange={handleInputChange}
              placeholder="Player Name"
            />
            <Input
              type="text"
              name="game"
              value={newPlayer.game}
              onChange={handleInputChange}
              placeholder="Game"
            />
            <Input
              type="text"
              name="platform"
              value={newPlayer.platform}
              onChange={handleInputChange}
              placeholder="Platform"
            />
            <Button onClick={handleRegisterPlayer}>Register</Button>
            {registrationError && <ErrorMessage>{registrationError}</ErrorMessage>}
          </RegistrationForm>
          
          <PlayerGridContainer>
          <PlayerHeader>{playerHeader}</PlayerHeader>
            <PlayerGrid>
              {activePlayers.map((player, index) => (
                <PlayerCard key={index} onClick={() => handlePlayerClick(player)}>
                  <DamageMultiplierContainer>
                    <DamageMultiplierIcon src={itemIcons['Damage-Multiplier']} alt="Damage Multiplier" />
                    <DamageMultiplierValue>
                      x{player.specialItems['Damage-Multiplier']}
                    </DamageMultiplierValue>
                  </DamageMultiplierContainer>
                  <PlayerHeader>
                    <AvatarContainer>
                      <AvatarImage src={player.avatar} alt={`Avatar for ${player.playerName}`} />
                      <LevelExpContainer>
                        Level: {player.level} | EXP: {player.exp}
                        <ProgressBarContainer>
                          <ProgressBarFill percentage={calculateExpPercentage(player.level, player.exp)} />
                        </ProgressBarContainer>
                      </LevelExpContainer>
                    </AvatarContainer>
                    <PlayerInfo>
                      <PlayerName>{player.playerName}</PlayerName>
                      <PlayerDetails>{player.game}</PlayerDetails>
                    </PlayerInfo>
                  </PlayerHeader>
                  <SpecialItems>
                    Special Items:
                    <ItemList>
                      {Object.entries(player.specialItems)
                        .filter(([item]) => item !== 'Damage-Multiplier')
                        .map(([item, count]) => (
                          <ItemListItem key={item}>
                            <ItemIcon src={itemIcons[item]} alt={item} />
                            {item}: {count}
                          </ItemListItem>
                        ))}
                    </ItemList>
                  </SpecialItems>
                  <RemoveButton 
                  onClick={(e) => handleRemovePlayer(e, player.playerName)}
                >
                  Remove
                </RemoveButton>
                </PlayerCard>
              ))}
            </PlayerGrid> 
          </PlayerGridContainer>
        </PlayerSection>
  
        <GameSection>
          <ControlButtons>
            <Button onClick={handleChickenDance}>Chicken Dance</Button>
            <Button onClick={handleHipHop}>Start</Button>
            <Button onClick={handleHit}>Hit</Button>
          </ControlButtons>
          <CanvasContainer>
          <Canvas shadows>
            <Scene action={action} timerSeconds={timerSeconds} health={health} />
          </Canvas>
          </CanvasContainer>
        </GameSection>

        <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="player-modal-title"
        aria-describedby="player-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <h2 id="player-modal-title">{selectedPlayer?.playerName}</h2>
          <TextField
            fullWidth
            label="Buy Price"
            type="number"
            value={buyPrice}
            onChange={(e) => setBuyPrice(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Ergebnis"
            type="number"
            value={result}
            onChange={(e) => setResult(e.target.value)}
            margin="normal"
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <MuiButton variant="contained" onClick={handleSetPlayerData}>
              Speichern
            </MuiButton>
            <MuiButton variant="outlined" onClick={handleModalClose}>
              Abbrechen
            </MuiButton>
          </Box>
        </Box>
      </Modal>

      </GameContainer>
    );
  };
  
  export default StreamerView_Bossfight;
  