import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import { useMediaQuery, ClickAwayListener } from '@mui/material';
import checkIcon from "../assets/check.png";
import closeIcon from '../assets/close.png';
import plusIcon from '../assets/plus.png';
import bankTransferIcon from '../assets/Zahlungsmethoden/bank-transfer.svg';
import cryptoIcon from '../assets/Zahlungsmethoden/crypto.svg';
import mastercardIcon from '../assets/Zahlungsmethoden/master-card.png';
import mifinityIcon from '../assets/Zahlungsmethoden/mifinity.svg';
import neosurfIcon from '../assets/Zahlungsmethoden/neosurf.svg';
import netellerIcon from '../assets/Zahlungsmethoden/neteller.svg';
import paysafeIcon from '../assets/Zahlungsmethoden/paysafecard.svg';
import skrillIcon from '../assets/Zahlungsmethoden/skrill.svg';
import visaIcon from '../assets/Zahlungsmethoden/visa.png';

const iconMap = {
  "Bank Transfer": bankTransferIcon,
  "Crypto": cryptoIcon,
  "Master Card": mastercardIcon,
  "MiFINITY": mifinityIcon,
  "Neosurf": neosurfIcon,
  "Neteller": netellerIcon,
  "Paysafe": paysafeIcon,
  "Skrill": skrillIcon,
  "Visa Card": visaIcon
};


const HeroContainer = styled.div`
  position: relative;
  background-color: ${props => props.backgroundColor || 'transparent'};
  background-image: ${props => props.backgroundImage ? `url(${props.backgroundImage})` : 'none'};
  background-size: ${props => props.backgroundSize || 'cover'};
  background-position: center;
  color: black;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  border: 2px solid #000; 
  background-repeat: repeat-x;

  @media (max-width: 768px) {
    background-size: cover;
  }
`;

const HeroImage = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 10px;
`;

const HeroTitle = styled.h1`
  font-size: 2rem;
  margin-bottom: 10px;
`;

const HeroFeaturesRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const HeroFeature = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;
`;

const FeatureText = styled.span`
  margin-left: 5px;
  font-size: 1.5rem;
  font-weight: 500;
`;

const HeroDescription = styled.p`
  font-size: 1rem;
  margin-bottom: 10px;
`;

const HeroButton = styled.button`
  background-color: #b5552c;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s;
  border: none;
  cursor: pointer;
  margin-top: 30px;

  &:hover {
    background-color: #041a11;
  }
`;

const Container = styled.div`
  background-color: #e9ecef;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 20px;
  text-align: left;
  position: relative;
  color: black;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;


const CardColGroup = styled.div`
  flex: 2;
  display: flex;
  justify-content: space-evenly;
  margin: 0 10px;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

const Stat = styled.div`
  text-align: center;
  margin: 0 10px;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 768px) {
    margin: 10px 0;
    width: auto;
    white-space: nowrap;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const SubtitleLarge = styled.p`
  font-size: 2em;
  margin: 0;
  margin-bottom: 10px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const Small = styled.small`
  color: #7f8c8d;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    padding-top: 20px;
    align-items: center;
  }
`;

const TermsLink = styled.small`
  color: #7f8c8d;
  margin-bottom: 10px;
`;

const Button = styled.a`
  background-color: #041a11;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-bottom: 10px;

  &:hover {
    background-color: #b5552c;
  }
`;

const MoreInfoButton = styled.button`
  background: none;
  border: none;
  color: #3498db;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  margin-top: 5px;

  img {
    margin-left: 5px;
    width: 15px;
    height: 15px;
  }
`;

const ExtraInfoContainer = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  margin-top: 20px;
  justify-content: space-between;
`;

const InfoList = styled.div`
  flex: 1;
  margin-right: 20px;
  padding-left: 110px;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  @media (max-width: 768px) {
    padding-left: 0px;
  }
`;

const rotate = keyframes`
  0%, 100% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%);
  }
  25% {
    clip-path: polygon(0% 0%, 0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  50% {
    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%, 0% 0%);
  }
  75% {
    clip-path: polygon(0% 0%, 0% 0%, 100% 100%, 0% 100%, 0% 0%);
  }
`;

const Tag = styled.span`
  color: red;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: transparent;
  position: relative;
  z-index: 1;
  display: inline-block;

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 5px;
    border: 2px solid transparent;
    background: linear-gradient(90deg, #08f, #f03) border-box;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    animation: ${rotate} 4s linear infinite;
  }
`;

const CardColGroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const PaymentTypeIcons = styled.div`
  flex: 1;

  .subtitle {
    margin-bottom: 10px;
  }

  .icon-row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

const BankingOption = styled.span`
  display: inline-block;
  margin: 5px;

  img {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 768px) {
    img {
      width: 50px;
      height: 50px;
    }
  }
`;

const CheckIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 5px;
`;

const CustomTooltip = ({ title, text, children, open, onClick }) => (
  <Tooltip
    title={
      <React.Fragment>
        <div style={{ fontWeight: 'bold', borderBottom: '1px solid #ddd', paddingBottom: '5px' }}>{title}</div>
        <div style={{ paddingTop: '5px' }}>{text}</div>
      </React.Fragment>
    }
    placement="bottom"
    open={open}
    onClick={onClick}
  >
    {children}
  </Tooltip>
);

const HeroSection = ({
  logo,
  title,
  tag,
  stats,
  link,
  extraInfo,
  bonusType,
  bonusAmount,
  maxBonus,
  maxBet,
  wager,
  wagerType,
  isNew,
  isDama,
}) => {
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [heroData, setHeroData] = useState(null);
  const [bonusTooltipOpen, setBonusTooltipOpen] = useState(false);
  const [wagerTooltipOpen, setWagerTooltipOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch('/heroCasi.json')
      .then(response => response.json())
      .then(data => {
        setHeroData(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error("Error fetching hero data:", error);
        setIsLoading(false);
      });
  }, []);

  const handleMoreInfoClick = () => {
    setShowMoreInfo(!showMoreInfo);
  };

  const getWagerTooltipText = (wagerType) => {
    switch (wagerType) {
      case 'B':
        return "The wager indicates how many times a bonus must be played through. This factor applies to the amount of the (B) bonus sum.";
      case 'D':
        return "The wager indicates how many times a bonus must be played through. This factor applies to the amount of the (D) deposit sum.";
      case 'D+B':
        return "The wager indicates how many times a bonus must be played through. This factor applies to the total amount of the (D+B) deposit sum and bonus sum.";
      case 'NoWager':
        return "NoWager means you can withdraw your money at any time. Your real money balance only needs to be played through once. The entire amount will be paid out, minus the granted bonus sum.";
      default:
        return "Information not available.";
    }
  };

  const getBonusTooltipText = (bonusType) => {
    switch (bonusType) {
      case 'Sticky':
        return "With a sticky bonus, your real money is tied to the bonus balance. To withdraw the amount, you must play through it several times according to the specified bonus conditions. Only when these wagering requirements are fully met can a withdrawal be made.";
      case 'NonSticky':
        return "With a non-sticky bonus, your own real money is used first. As a result, any winnings you generate with it are immediately withdrawable without bonus conditions. The real money and the bonus money are clearly separated. If you use the bonus balance, you must meet the wagering requirements.";
      default:
        return "Information not available.";
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!heroData) {
    return <div>Error loading data</div>;
  }

  return (
    <HeroContainer
      backgroundImage={heroData.backgroundImage}
      backgroundSize={heroData.backgroundSize || 'cover'}
      backgroundColor={heroData.backgroundColor || 'transparent'}
      showMoreInfo={showMoreInfo}
    >
      <HeroImage src={heroData.logo} alt="Casino Logo" />
      <HeroTitle>{heroData.HeroSectionTitle}</HeroTitle>
      
      {heroData.HeroFeatures && (
        <HeroFeaturesRow>
          {heroData.HeroFeatures.map((feature, index) => (
            <HeroFeature key={index}>
              <CheckIcon src={checkIcon} alt="check" />
              <FeatureText>{feature}</FeatureText>
            </HeroFeature>
          ))}
        </HeroFeaturesRow>
      )}
  
      <HeroDescription>{heroData.description}</HeroDescription>
      <Tag>{heroData.tag}</Tag>
      <CardColGroupContainer>
        <CardColGroup>
          <Stat>
            <SubtitleLarge>{heroData.bonusAmount}%</SubtitleLarge>
            <Small>
              {heroData.bonusType}
              <ClickAwayListener onClickAway={() => setBonusTooltipOpen(false)}>
                <div>
                  <CustomTooltip
                    title="Bonus Info"
                    text={getBonusTooltipText(heroData.bonusType)}
                    open={bonusTooltipOpen}
                    onClick={() => setBonusTooltipOpen(!bonusTooltipOpen)}
                  >
                    <HelpTwoToneIcon
                      onMouseEnter={isMobile ? undefined : () => setBonusTooltipOpen(true)}
                      onMouseLeave={isMobile ? undefined : () => setBonusTooltipOpen(false)}
                    />
                  </CustomTooltip>
                </div>
              </ClickAwayListener>
            </Small>
          </Stat>
          <Stat>
            <SubtitleLarge>{heroData.maxBonus}€</SubtitleLarge>
            <Small>Max Bonus</Small>
          </Stat>
          <Stat>
            <SubtitleLarge>{heroData.maxBet}€</SubtitleLarge>
            <Small>Max Bet</Small>
          </Stat>
          <Stat>
            <SubtitleLarge>{heroData.wager}x {heroData.wagerType !== 'NoWager' && heroData.wagerType}</SubtitleLarge>
            <Small>
              {heroData.wagerType === 'NoWager' ? 'No Wager' : 'Wager'}
              <ClickAwayListener onClickAway={() => setWagerTooltipOpen(false)}>
                <div>
                  <CustomTooltip
                    title="Wager Info"
                    text={getWagerTooltipText(heroData.wagerType)}
                    open={wagerTooltipOpen}
                    onClick={() => setWagerTooltipOpen(!wagerTooltipOpen)}
                  >
                    <HelpTwoToneIcon
                      onMouseEnter={isMobile ? undefined : () => setWagerTooltipOpen(true)}
                      onMouseLeave={isMobile ? undefined : () => setWagerTooltipOpen(false)}
                    />
                  </CustomTooltip>
                </div>
              </ClickAwayListener>
            </Small>
          </Stat>
        </CardColGroup>
        <Actions>
          <TermsLink>Current casino terms and conditions apply</TermsLink>
          <Button href={heroData.link} rel="nofollow" target="_blank">
            Play Now
          </Button>
        </Actions>
      </CardColGroupContainer>
      <HeroButton onClick={handleMoreInfoClick}>
        {showMoreInfo ? "Less Information" : "More Information"}
      </HeroButton>
      {showMoreInfo && (
        <Container>
          <ExtraInfoContainer show={showMoreInfo}>
            <InfoList>
              <p className="subtitle">Casino Highlight</p>
              <ul>
                {heroData.extraInfo.highlights.map((highlight, index) => (
                  <li key={index}>
                    <CheckIcon src={checkIcon} alt="check" />
                    {highlight}
                  </li>
                ))}
              </ul>
            </InfoList>
            <PaymentTypeIcons>
              <p className="subtitle">Payment Methods</p>
              <div className="icon-row">
                {heroData.extraInfo.paymentMethods.map((method, index) => (
                  <BankingOption key={index} title={method.name}>
                    <img src={iconMap[method.name]} alt={method.name} />
                  </BankingOption>
                ))}
              </div>
            </PaymentTypeIcons>
          </ExtraInfoContainer>
        </Container>
      )}
    </HeroContainer>
  );
  
};

export default HeroSection;