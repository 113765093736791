import React from 'react';
import styled from 'styled-components';
import SocialLinks from './SocialLinks';

const CommunityContactContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 2.5em;
  color: #041a11;
  text-align: center;
  margin-bottom: 30px;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 1.8em;
  color: #333;
  margin-bottom: 20px;
`;

const Text = styled.p`
  font-size: 1.1em;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
`;

const Button = styled.a`
  display: inline-block;
  background-color: #041a11;
  color: white;
  padding: 12px 24px;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #5e1fa6;
  }
`;

const CommunityContact = () => {
  return (
    <CommunityContactContainer>
      <Title>Community & Contact</Title>

      <Section>
        <SectionTitle>Our Community</SectionTitle>
        <Text>
          Welcome to the ToHighForSlots community! This is where like-minded people come together 
          to share experiences, enjoy slotting together, and have fun. 
          Our Discord server is the central hub for all community activities.
        </Text>
        <Text>
          What you can expect:
          <ul>
            <li>Shared slot sessions and tournaments</li>
            <li>Discussions about strategies and new games</li>
            <li>Exclusive tips and bonus information</li>
            <li>Friendly interactions in a positive atmosphere</li>
            <li>Additional giveaways</li>
          </ul>
        </Text>
        <Button href="https://discord.gg/m8nhJyje" target="_blank" rel="noopener noreferrer">
          Join our Discord
        </Button>
      </Section>

      <SocialLinks />
    </CommunityContactContainer>
  );
};


export default CommunityContact;