import React, { useRef, useState, useEffect, useCallback } from 'react';
  import { Canvas, useFrame, useThree } from '@react-three/fiber';
  import { OrbitControls, Environment, PerspectiveCamera, Text } from '@react-three/drei';
  import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
  import * as THREE from 'three';
  import axios from 'axios';
  import styled, { keyframes, css } from 'styled-components';
  import levelData from './Bossfight_Level.json';

  // Import avatar images
  import Avatar1 from '../assets/Avatar_1.png';
  import Avatar2 from '../assets/Avatar_2.png';
  import Avatar3 from '../assets/Avatar_3.png';
  import Avatar4 from '../assets/Avatar_4.png';
  import Avatar5 from '../assets/Avatar_5.png';
  import Avatar6 from '../assets/Avatar_6.png';
  import Avatar7 from '../assets/Avatar_7.png';
  import Avatar8 from '../assets/Avatar_8.png';
  import Avatar9 from '../assets/Avatar_9.png';

  // Import item icons
  import bombeIcon from '../assets/Bombe_Icon.png';
  import giftpfeilIcon from '../assets/Giftpfeil_Icon.png';
  import damageMultiplierIcon from '../assets/DamageMultiplier_Icon.png';

  const GameContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
  width: 100%; // Stellt sicher, dass der Container die volle Breite einnimmt
`;

const PlayerSection = styled.div`
  flex: 0 0 35%; // Erhöhen Sie dies von 30% auf 35% oder mehr, wenn nötig
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100vh;
  box-sizing: border-box;

`;

const GameSection = styled.div`
  flex: 1; // Dies lässt die GameSection den restlichen Platz einnehmen
  display: flex;
  flex-direction: column;
`;

const CanvasContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Button = styled.button`
  margin: 5px;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;


const PlayerGridContainer = styled.div`
  flex-grow: 1;
  width: 100%; // Stellt sicher, dass es die volle Breite der PlayerSection einnimmt
`;

const PlayerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 10px;
  width: 100%; // Stellt sicher, dass es die volle Breite einnimmt
`;

const PlayerCard = styled.div`
  background-color: #E3E3E3;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  position: relative;
  width: 400px; // Lässt die Karte ihre natürliche Breite einnehmen

  &:hover {
    transform: translateY(-5px);
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(5%);
    opacity: 1;
  }
`;

const AnimatedPlayerCard = styled(PlayerCard)`
  opacity: ${props => props.show ? 1 : 0};
  transform: ${props => props.show ? props.transform : 'translateX(-100%)'};
  transition: opacity ${props => props.duration}ms ease-out, 
              transform ${props => props.duration}ms ease-out;
  
  &:hover {
    transform: ${props => `${props.transform} translateY(-5px)`};
  }
`;

const calculateTransform = (index) => {
  const baseOffset = 0; // Basisversatz für alle Karten
  const extraOffset = 30; // Zusätzlicher Versatz für jede zweite Karte
  const isEvenIndex = index % 2 === 0;
  
  return `translateX(${isEvenIndex ? baseOffset : baseOffset + extraOffset}%)`;
};

const PlayerHeader = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
`;

const DamageMultiplierContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
`;

const DamageMultiplierIcon = styled.img`
  width: 36px;
  height: 36px;
  z-index: 1;
`;

const DamageMultiplierValue = styled.div`
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
`;

const PlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
`;

const AvatarImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
`;

const PlayerName = styled.h3`
  margin: 0;
  font-size: 18px;
`;

const PlayerDetails = styled.p`
  margin: 2px 0;
  font-size: 14px;
  color: #666;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

const SpecialItems = styled.div`
  margin-top: 10px;
  font-size: 12px;
`;

const ItemList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 5px 0 0 0;
`;

const ItemIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
`;

const ItemListItem = styled.li`
  margin: 2px 0;
  display: flex;
  align-items: center;
`;

const ControlButtons = styled.div`
  padding: 10px;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 10px;
  background-color: #9B9B9B;
  border-radius: 5px;
  margin-top: 5px;
`;

const ProgressBarFill = styled.div`
  height: 100%;
  background-color: #4caf50;
  border-radius: 5px;
  width: ${props => props.percentage}%;
  transition: width 0.3s ease-in-out;
`;

const LevelExpContainer = styled.div`
  text-align: center;
  font-size: 12px;
  color: #666;
  width: 100%;
`;

  const Character = ({ action }) => {
  const ref = useRef();
  const [mixer] = useState(() => new THREE.AnimationMixer());
  const [idle, setIdle] = useState(null);
  const [chickenDance, setChickenDance] = useState(null);
  const [hipHop, setHipHop] = useState(null);
  const [hit, setHit] = useState(null);
  const [fallingDeath, setFallingDeath] = useState(null);
  const [lyingDeath, setLyingDeath] = useState(null);

  useEffect(() => {
    const loader = new FBXLoader();

    const loadAnimation = (file, setter) => {
      loader.load(`/${file}.fbx`, (fbx) => {
        setter(fbx.animations[0]);
        if (file === 'idle') {
          fbx.scale.set(0.01, 0.01, 0.01);
          ref.current.add(fbx);
        }
      }, undefined, (error) => {
        console.error(`Error loading ${file}.fbx:`, error);
      });
    };

    loadAnimation('idle', setIdle);
    loadAnimation('ChickenDance', setChickenDance);
    loadAnimation('HipHop', setHipHop);
    loadAnimation('hit', setHit);
    loadAnimation('FallingDeath', setFallingDeath);
    loadAnimation('LyingDeath', setLyingDeath);

    return () => mixer.stopAllAction();
  }, [mixer]);

  useEffect(() => {
    if (idle && chickenDance && hipHop && hit && fallingDeath && lyingDeath) {
      const idleAction = mixer.clipAction(idle, ref.current);
      const chickenDanceAction = mixer.clipAction(chickenDance, ref.current);
      const hipHopAction = mixer.clipAction(hipHop, ref.current);
      const hitAction = mixer.clipAction(hit, ref.current);
      const fallingDeathAction = mixer.clipAction(fallingDeath, ref.current);
      const lyingDeathAction = mixer.clipAction(lyingDeath, ref.current);

      mixer.stopAllAction();

      switch (action) {
        case 'chickenDance':
          chickenDanceAction.reset().play();
          chickenDanceAction.setLoop(THREE.LoopRepeat);
          break;
        case 'hipHop':
          hipHopAction.reset().play();
          hipHopAction.setLoop(THREE.LoopRepeat);
          break;
        case 'hit':
          hitAction.reset().play();
          hitAction.setLoop(THREE.LoopOnce);
          hitAction.clampWhenFinished = true;
          break;
        case 'fallingDeath':
          fallingDeathAction.reset().play();
          fallingDeathAction.setLoop(THREE.LoopOnce);
          fallingDeathAction.clampWhenFinished = true;
          break;
        case 'lyingDeath':
          lyingDeathAction.reset().play();
          lyingDeathAction.setLoop(THREE.LoopOnce);
          lyingDeathAction.clampWhenFinished = true;
          break;
        default:
          idleAction.reset().play();
      }
    }
  }, [action, idle, chickenDance, hipHop, hit, fallingDeath, lyingDeath, mixer]);

  useFrame((_, delta) => mixer.update(delta));

  return <group ref={ref} position={[0, -0.5, 0]} />;
};

const Lighting = () => {
  return (
    <>
      <ambientLight intensity={1.5} />
      <directionalLight
        position={[5, 5, 5]}
        intensity={1}
        castShadow
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
      />
      <pointLight position={[-5, 5, -5]} intensity={0.5} />
    </>
  );
};

const Healthbar = ({ health }) => {
  const width = 1;
  const height = 0.1;
  const depth = 0.05;

  const getColor = () => {
    if (health > 50) return 'green';
    if (health > 20) return 'orange';
    return 'red';
  };

  return (
    <group position={[0, 1.5, 0]}>
      <mesh>
        <boxGeometry args={[width, height, depth]} />
        <meshBasicMaterial color="gray" />
      </mesh>
      <mesh position={[-(width - width * health / 1000) / 2, 0, depth / 2 + 0.001]}>
        <boxGeometry args={[width * health / 1000, height, 0.01]} />
        <meshBasicMaterial color={getColor()} />
      </mesh>
    </group>
  );
};

const Scene = ({ action, timerSeconds, health }) => {
  console.log("Current Action:", action);
  console.log("Timer Seconds:", timerSeconds);

  return (
    <>
      <PerspectiveCamera makeDefault position={[-1, 0.5, 4]} fov={50} />
      <Lighting />
      <Character action={action} />
      <Healthbar health={health} />
      <Environment preset="studio" />
      <mesh rotation={[-Math.PI / 2, 0, 0]} position={[-1, -0.5, 0]} receiveShadow>
        <planeGeometry args={[10, 10]} />
        <shadowMaterial opacity={0.4} />
      </mesh>
      {action === 'hipHop' && <Timer seconds={timerSeconds} />}
    </>
  );
};

const Timer = ({ seconds }) => {
  return (
    <Text
      position={[0, 1.75, 0]} // Adjusted position to ensure visibility below the character
      fontSize={0.2}
      color="white"
      anchorX="center"
      anchorY="middle"
    >
      {`Let's get Readyyy ... ${seconds}`}
    </Text>
  );
};
  
  
  
  const Bossfight_ViewerView = () => {
    const [action, setAction] = useState('idle');
    const [timerSeconds, setTimerSeconds] = useState(30);
    const [health, setHealth] = useState(1000);
    const audioRef = useRef(null);
    const introAudioRef = useRef(null);
    const ouchAudioRefs = useRef([]);
    const ouchLongAudioRef = useRef(null);
    const timeoutRef = useRef(null);
    const intervalRef = useRef(null);
    const cycleCountRef = useRef(0);
    const wsRef = useRef(null);
    const [activePlayers, setActivePlayers] = useState([]);
    const [showPlayerCards, setShowPlayerCards] = useState(false);
    const [animationCompleted, setAnimationCompleted] = useState(false);
    const [animationStartTime, setAnimationStartTime] = useState(null);
    const [currentTime, setCurrentTime] = useState(Date.now());
    const [playerAnimationStates, setPlayerAnimationStates] = useState([]);
    const audioLoopRef = useRef(null);
    const isPlayingRef = useRef(false);
    const [audioPlayCount, setAudioPlayCount] = useState(0);


    useEffect(() => {
      if (showPlayerCards && animationStartTime) {
        const totalAnimationDuration = 30000; // 30 Sekunden in Millisekunden
        const cardCount = activePlayers.length;
        const animationDurationPerCard = totalAnimationDuration / cardCount;
    
        setPlayerAnimationStates(new Array(cardCount).fill(false));
    
        const animationInterval = setInterval(() => {
          const currentTime = Date.now();
          const elapsedTime = currentTime - animationStartTime;
          
          if (elapsedTime >= totalAnimationDuration) {
            setPlayerAnimationStates(new Array(cardCount).fill(true));
            clearInterval(animationInterval);
          } else {
            // Hier berechnen wir den Index der aktuell einfliegenden Karte
            const currentCardIndex = Math.floor(elapsedTime / animationDurationPerCard);
            setPlayerAnimationStates(prevStates => {
              const newStates = [...prevStates];
              for (let i = 0; i <= currentCardIndex; i++) {
                newStates[i] = true;
              }
              return newStates;
            });
          }
        }, 50); // Häufigere Updates für flüssigere Animation
    
        return () => clearInterval(animationInterval);
      }
    }, [showPlayerCards, animationStartTime, activePlayers]);

  
    const avatars = [
      Avatar1, Avatar2, Avatar3, Avatar4, Avatar5, Avatar6, Avatar7, Avatar8, Avatar9
    ];
  
    const itemIcons = {
      Bomben: bombeIcon,
      Giftpfeile: giftpfeilIcon,
      'Damage-Multiplier': damageMultiplierIcon
    };
  
    const getRandomAvatar = useCallback(() => {
      const randomIndex = Math.floor(Math.random() * avatars.length);
      return avatars[randomIndex];
    }, [avatars]);
  
    useEffect(() => {
        const wsUrl = `ws://${process.env.REACT_APP_WS_URL}?clientType=BossfightViewer`;
        wsRef.current = new WebSocket(wsUrl);
      
        wsRef.current.onopen = () => {
          console.log('WebSocket connected');
        };
      
        wsRef.current.onmessage = (event) => {
          const message = JSON.parse(event.data);
          console.log('Received message:', message);
          if (message.action) {
            switch (message.action) {
              case 'chickenDance':
                handleChickenDance();
                break;
              case 'hipHop':
                handleHipHop();
                break;
              case 'hit':
                handleHit();
                break;
              default:
                console.error('Unknown action:', message.action);
            }
          }
        };
      
        wsRef.current.onerror = (error) => {
          console.error('WebSocket error:', error);
        };
      
        wsRef.current.onclose = (event) => {
          console.log('WebSocket disconnected:', event);
        };
      
        audioRef.current = new Audio('/ChickenDance.mp3');
        audioRef.current.load();
        introAudioRef.current = new Audio('/Intro.mp3');
        introAudioRef.current.load();
        ouchLongAudioRef.current = new Audio('/Ouch_Long_1.mp3');
        ouchLongAudioRef.current.load();
      
        ouchAudioRefs.current = [
          new Audio('/Ouch.mp3'),
          new Audio('/Ouch_2.mp3'),
          new Audio('/Ouch_3.mp3')
        ];
        ouchAudioRefs.current.forEach(audio => audio.load());
      
        fetchActivePlayers();
      
        return () => {
          if (timeoutRef.current) clearTimeout(timeoutRef.current);
          if (intervalRef.current) clearInterval(intervalRef.current);
      
          if (wsRef.current) {
            wsRef.current.close();
          }
      
          const stopAudio = (audio) => {
            if (audio && typeof audio.pause === 'function') {
              audio.pause();
              audio.currentTime = 0;
            }
          };
      
          stopAudio(audioRef.current);
          stopAudio(introAudioRef.current);
          stopAudio(ouchLongAudioRef.current);
          ouchAudioRefs.current.forEach(stopAudio);
        };
      }, []);
      
  
    const handleChickenDance = () => {
      if (audioRef.current) {
        audioRef.current.currentTime = 0;
        audioRef.current.play();
      }
  
      timeoutRef.current = setTimeout(() => {
        setAction('chickenDance');
      }, 350);
  
      timeoutRef.current = setTimeout(() => {
        setAction('idle');
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
      }, 5000);
    };

    useEffect(() => {
      const timer = setInterval(() => setCurrentTime(Date.now()), 100); // Update every 100ms
      return () => clearInterval(timer);
    }, []);
    const playAudio = async (audio) => {
    if (isPlayingRef.current) {
      await stopAudio(audio);
    }
    try {
      isPlayingRef.current = true;
      await audio.play();
    } catch (error) {
      console.error('Error playing audio:', error);
    }
  };



  const stopAudio = async (audio) => {
    try {
      await audio.pause();
      audio.currentTime = 0;
      isPlayingRef.current = false;
    } catch (error) {
      console.error('Error stopping audio:', error);
    }
  };

  
  
  const handleHipHop = (duration) => {
    const totalSeconds = duration ? duration / 1000 : 30;
    setTimerSeconds(totalSeconds);
    setAction('hipHop');
    setShowPlayerCards(true);
    setAnimationStartTime(Date.now());
    setPlayerAnimationStates(new Array(activePlayers.length).fill(false));
    setAudioPlayCount(0);
  
    const playIntroAudio = () => {
      if (introAudioRef.current && audioPlayCount < 3) {
        playAudio(introAudioRef.current);
        setAudioPlayCount(prevCount => prevCount + 1);
      }
    };
  
    playIntroAudio(); // Play immediately for the first time
  
    if (audioLoopRef.current) clearInterval(audioLoopRef.current);
    audioLoopRef.current = setInterval(() => {
      if (audioPlayCount < 3) {
        playIntroAudio();
      } else {
        clearInterval(audioLoopRef.current);
      }
    }, 10000); // Repeat every 10 seconds
  
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setTimerSeconds(prev => {
        if (prev > 0) return prev - 1;
        if (prev === 0) {
          clearInterval(intervalRef.current);
          clearInterval(audioLoopRef.current);
          setAction('idle');
          if (introAudioRef.current) {
            stopAudio(introAudioRef.current);
          }
        }
        return prev;
      });
    }, 1000);
  
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    
    timeoutRef.current = setTimeout(() => {
      setAction('idle');
      clearInterval(audioLoopRef.current);
      if (introAudioRef.current) {
        stopAudio(introAudioRef.current);
      }
    }, duration || 30000);
  };

  useEffect(() => {
    return () => {
      if (audioLoopRef.current) {
        clearInterval(audioLoopRef.current);
      }
      if (introAudioRef.current) {
        stopAudio(introAudioRef.current);
      }
      setAudioPlayCount(0);
    };
  }, []);
  
    const handleHit = () => {
      const newHealth = Math.max(health - 10, 0);
      setHealth(newHealth);
  
      if (newHealth > 0) {
        setAction('hit');
        const randomIndex = Math.floor(Math.random() * 3);
        const ouchAudio = ouchAudioRefs.current[randomIndex];
        if (ouchAudio) {
          ouchAudio.currentTime = 0;
          ouchAudio.play();
        }
  
        timeoutRef.current = setTimeout(() => {
          setAction('idle');
        }, 1000);
      } else {
        setAction('fallingDeath');
        if (ouchLongAudioRef.current) {
          ouchLongAudioRef.current.currentTime = 0;
          ouchLongAudioRef.current.play();
        }
        timeoutRef.current = setTimeout(() => {
          setAction('lyingDeath');
        }, 2000);
      }
    };
  
  
 
  
    const calculateExpPercentage = (level, exp) => {
      const currentLevelData = levelData.levels.find(l => l.level === level);
      const nextLevelData = levelData.levels.find(l => l.level === level + 1);
      
      if (!currentLevelData || !nextLevelData) return 0;
  
      const expForCurrentLevel = currentLevelData.expRequired;
      const expForNextLevel = nextLevelData.expRequired;
      const expNeededForNextLevel = expForNextLevel - expForCurrentLevel;
      const currentLevelProgress = exp - expForCurrentLevel;
  
      return Math.min((currentLevelProgress / expNeededForNextLevel) * 100, 100);
    };
  
    
  
    const fetchActivePlayers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_ADDRESS}/api/activeBossfightPlayers`);
        setActivePlayers(response.data);
      } catch (error) {
        console.error('Error fetching active players:', error);
      }
    };
  

  
  
  
    const [isInitialized, setIsInitialized] = useState(false);

const initializeApp = () => {
  // Alle Audio-Dateien laden und WebSocket-Verbindung herstellen
  audioRef.current = new Audio('/ChickenDance.mp3');
  introAudioRef.current = new Audio('/Intro.mp3');
  ouchLongAudioRef.current = new Audio('/Ouch_Long_1.mp3');

  ouchAudioRefs.current = [
    new Audio('/Ouch.mp3'),
    new Audio('/Ouch_2.mp3'),
    new Audio('/Ouch_3.mp3')
  ];

  // WebSocket-Verbindung herstellen
  const wsUrl = `ws://${process.env.REACT_APP_WS_URL}?clientType=BossfightViewer`;
  wsRef.current = new WebSocket(wsUrl);

  wsRef.current.onopen = () => {
    console.log('WebSocket connected');
  };

  wsRef.current.onmessage = (event) => {
    const message = JSON.parse(event.data);
    console.log('Received message:', message);

    switch (message.action) {
      case 'chickenDance':
        handleChickenDance();
        break;
      case 'hipHop':
        handleHipHop(message.duration);
        break;
      case 'hit':
        handleHit();
        break;
      case 'idle':
        setAction('idle');
        break;
      default:
        console.error('Unknown action:', message.action);
    }
  };

  wsRef.current.onerror = (error) => {
    console.error('WebSocket error:', error);
  };

  wsRef.current.onclose = (event) => {
    console.log('WebSocket disconnected:', event);
  };

  fetchActivePlayers();

  setIsInitialized(true);
};

return (
  <GameContainer>
    {!isInitialized ? (
      <div>
        <Button onClick={initializeApp}>Start Game</Button>
      </div>
    ) : (
      <>
        <PlayerSection>          
          <PlayerGridContainer>
            <PlayerGrid>
              {activePlayers.map((player, index) => {
                const totalPlayers = activePlayers.length;
                const animationDuration = 30 / totalPlayers;

                return (
<AnimatedPlayerCard 
      key={index} 
      duration={30000 / activePlayers.length} // Animationsdauer pro Karte
      show={playerAnimationStates[index]}
      transform={calculateTransform(index)}
    >
                    <DamageMultiplierContainer>
                      <DamageMultiplierIcon src={itemIcons['Damage-Multiplier']} alt="Damage Multiplier" />
                      <DamageMultiplierValue>
                        x{player.specialItems['Damage-Multiplier']}
                      </DamageMultiplierValue>
                    </DamageMultiplierContainer>
                    <PlayerHeader>
                      <AvatarContainer>
                        <AvatarImage src={player.avatar} alt={`Avatar for ${player.playerName}`} />
                        <LevelExpContainer>
                          Level: {player.level} | EXP: {player.exp}
                          <ProgressBarContainer>
                            <ProgressBarFill percentage={calculateExpPercentage(player.level, player.exp)} />
                          </ProgressBarContainer>
                        </LevelExpContainer>
                      </AvatarContainer>
                      <PlayerInfo>
                        <PlayerName>{player.playerName}</PlayerName>
                        <PlayerDetails>{player.game}</PlayerDetails>
                      </PlayerInfo>
                    </PlayerHeader>
                    <SpecialItems>
                      Special Items:
                      <ItemList>
                        {Object.entries(player.specialItems)
                          .filter(([item]) => item !== 'Damage-Multiplier')
                          .map(([item, count]) => (
                            <ItemListItem key={item}>
                              <ItemIcon src={itemIcons[item]} alt={item} />
                              {item}: {count}
                            </ItemListItem>
                          ))}
                      </ItemList>
                    </SpecialItems>
                  </AnimatedPlayerCard>
                );
              })}
            </PlayerGrid> 
          </PlayerGridContainer>
        </PlayerSection>

        <GameSection>
          <CanvasContainer>
            <Canvas shadows>
              <Scene action={action} timerSeconds={timerSeconds} health={health} />
            </Canvas>
          </CanvasContainer>
        </GameSection>
      </>
    )}
  </GameContainer>
);
};

export default Bossfight_ViewerView;