import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import HeaderLogo from '../assets/Logo_THFS.jpg';
import HeaderBanner from '../assets/Header-Banner_THFS.png';
import BoniIcon from '../assets/Boni.png';
import KickIcon from '../assets/Socials/kick.png';
import YoutubeIcon from '../assets/Socials/youtube.svg';
import TwitchIcon from '../assets/Socials/twitch.svg';
import InstaIcon from '../assets/Socials/insta.svg';
import DiscordIcon from '../assets/Socials/discord.svg';

const HeaderContainer = styled.header`
  background-color: #2c3e50;
  background-image: url(${HeaderBanner});
  background-size: cover;
  background-position: center;
  height: 300px;
  padding: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;
    justify-content: center;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  max-width: 300px;
  margin-bottom: 10px;
`;

const NavWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-top: 250px;

  @media (max-width: 768px) {
    width: 100%;
    order: 2;
  }
`;

const NavLinks = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin: 0 20px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  transition: all 0.3s ease;
  animation: ${pulse} 2s infinite;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-3px);
  }

  img {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;

  @media (max-width: 768px) {
    margin-top: -20px;
  }
`;

const SocialLink = styled.a`
  color: white;
  margin: 0 10px;

  img {
    width: 40px;
    height: 40px;
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <FlexContainer>
        <Logo src={HeaderLogo} alt="ToHighForSlots Logo" />
        <NavWrapper>
          <NavLinks>
            <NavLink to="/casino-bonus">
              <img src={BoniIcon} alt="Casino Bonuses Icon" /> Casino Bonus
            </NavLink>
            <NavLink to="/giveaway">
              <img src={BoniIcon} alt="Giveaway Icon" /> Giveaway
            </NavLink>            
            <NavLink to="/community">
              <img src={BoniIcon} alt="Community_Contact" /> Community & Contact
            </NavLink>
          </NavLinks>
        </NavWrapper>
      </FlexContainer>
    </HeaderContainer>
  );
};

export default Header;