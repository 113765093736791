import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { SketchPicker } from 'react-color'; // Import SketchPicker
import checkIcon from '../assets/check.png';
import closeIcon from '../assets/close.png';
import plusIcon from '../assets/plus.png';
import bankTransferIcon from '../assets/Zahlungsmethoden/bank-transfer.svg';
import cryptoIcon from '../assets/Zahlungsmethoden/crypto.svg';
import mastercardIcon from '../assets/Zahlungsmethoden/master-card.png';
import mifinityIcon from '../assets/Zahlungsmethoden/mifinity.svg';
import neosurfIcon from '../assets/Zahlungsmethoden/neosurf.svg';
import netellerIcon from '../assets/Zahlungsmethoden/neteller.svg';
import paysafeIcon from '../assets/Zahlungsmethoden/paysafecard.svg';
import skrillIcon from '../assets/Zahlungsmethoden/skrill.svg';
import visaIcon from '../assets/Zahlungsmethoden/visa.png';

const iconMap = {
  "Bank Überweisung": bankTransferIcon,
  "Crypto": cryptoIcon,
  "Master Card": mastercardIcon,
  "MiFINITY": mifinityIcon,
  "Neosurf": neosurfIcon,
  "Neteller": netellerIcon,
  "Paysafe": paysafeIcon,
  "Skrill": skrillIcon,
  "Visa Card": visaIcon
};

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  text-align: left;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
`;

const Input = styled.input`
  margin: 5px 0;
  padding: 12px;
  width: 100%;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: border-color 0.3s;

  &:focus {
    border-color: #3498db;
    outline: none;
  }
`;

const TextArea = styled.textarea`
  margin: 5px 0;
  padding: 12px;
  width: 100%;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: border-color 0.3s;

  &:focus {
    border-color: #3498db;
    outline: none;
  }
`;

const Button = styled.button`
  padding: 12px 24px;
  font-size: 1em;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2980b9;
  }
`;

const CancelButton = styled(Button)`
  background-color: #e74c3c;
  margin-left: 10px;

  &:hover {
    background-color: #c0392b;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-size: 0.9em;
`;

const Checkbox = styled.input`
  margin-right: 10px;
  transform: scale(1.2);
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Tag = styled.span`
  background-color: #2ecc71;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const CardColGroup = styled.div`
  flex: 2;
  display: flex;
  justify-content: space-around;
`;

const Stat = styled.div`
  text-align: center;
`;

const SubtitleLarge = styled.p`
  font-size: 2em;
  margin: 0;
  margin-bottom: 10px;
  font-weight: 600;
`;

const Small = styled.small`
  color: #7f8c8d;
  font-size: 1.2em;
`;

const Actions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TermsLink = styled.small`
  color: #7f8c8d;
  margin-bottom: 10px;
`;

const MoreInfoButton = styled.button`
  background: none;
  border: none;
  color: #3498db;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  margin-top: 5px;

  img {
    margin-left: 5px;
    width: 15px;
    height: 15px;
  }
`;

const ExtraInfoContainer = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  margin-top: 20px;
  justify-content: space-between;
`;

const InfoList = styled.div`
  flex: 1;
  margin-right: 20px;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
`;

const PaymentTypeIcons = styled.div`
  flex: 1;

  .subtitle {
    margin-bottom: 10px;
  }

  .icon-row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

const BankingOption = styled.span`
  display: inline-block;
  margin: 5px;

  img {
    width: 50px;
    height: 50px;
  }
`;

const CheckIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 5px;
`;

const ContainerPreview = styled.section`
  background-color: #e9ecef;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
`;

const HeroContainerPreview = styled.div`
  position: relative;
  background-color: ${props => props.backgroundImage ? 'transparent' : props.backgroundColor || 'transparent'};
  background-image: ${props => props.backgroundImage ? `url(${props.backgroundImage})` : 'none'};
  background-size: ${props => props.backgroundSize || 'cover'};
  background-position: center;
  color: black;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const HeroImage = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 10px;
`;

const HeroTitle = styled.h1`
  font-size: 2rem;
  margin-bottom: 10px;
`;

const HeroFeaturesRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const HeroFeature = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;
`;

const FeatureText = styled.span`
  margin-left: 5px;
  font-size: 1.5rem;
  font-weight: 500;
`;

const HeroDescription = styled.p`
  font-size: 1rem;
  margin-bottom: 10px;
`;

const HeroButton = styled.button`
  background-color: #b5552c;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #2ecc71;
  }
`;

const EditHeroCasiModal = ({ entry, onClose, onSave, onChange, isNew }) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [showExtraInfo, setShowExtraInfo] = useState(false);
  const [logos, setLogos] = useState([]);
  const [backgroundImages, setBackgroundImages] = useState([]);  // Add state for background images
  const [showLogos, setShowLogos] = useState(false);
  const [showBackgroundImages, setShowBackgroundImages] = useState(false);  // Add state for showing background images

  useEffect(() => {
    axios.get(`${API_URL}/api/logos`)
      .then(response => setLogos(response.data))
      .catch(error => console.error('Error fetching logos:', error));
    
    axios.get(`${API_URL}/api/background-images`)  // Fetch background images
      .then(response => setBackgroundImages(response.data))
      .catch(error => console.error('Error fetching background images:', error));
  }, [API_URL]);

  const allPaymentMethods = [
    "Bank Überweisung",
    "Crypto",
    "Master Card",
    "MiFINITY",
    "Neosurf", 
    "Neteller",
    "Paysafe",
    "Skrill",
    "Visa Card",
  ];

  const filterBonusTypes = ["Sticky", "NonSticky", "NoDeposit", "Wagerfree", "LowWager", "KeinMaxcashout"];
  const [bonusType, setBonusType] = useState(entry.bonusType || "");
  const [wagerType, setWagerType] = useState(entry.wagerType || "");

  const handleCheckboxChange = (method) => {
    const paymentMethods = entry.extraInfo.paymentMethods || [];
    const updatedMethods = paymentMethods.map(pm => pm.name);
    if (updatedMethods.includes(method)) {
        const newMethods = updatedMethods.filter(m => m !== method);
        onChange({
            target: {
                name: 'extraInfo',
                value: { ...entry.extraInfo, paymentMethods: newMethods.map(name => ({ name, icon: '' })) },
            },
        });
    } else {
        const newMethods = [...updatedMethods, method];
        onChange({
            target: {
                name: 'extraInfo',
                value: { ...entry.extraInfo, paymentMethods: newMethods.map(name => ({ name, icon: '' })) },
            },
        });
    }
};


const handleFilterBonusTypeChange = (type) => {
  const updatedTypes = entry.filterBonusTypes || [];
  if (updatedTypes.includes(type)) {
      onChange({
          target: {
              name: 'filterBonusTypes',
              value: updatedTypes.filter(t => t !== type),
          },
      });
  } else {
      onChange({
          target: {
              name: 'filterBonusTypes',
              value: [...updatedTypes, type],
          },
      });
  }
};


  const handleBonusTypeChange = (type) => {
    setBonusType(type);
    onChange({
      target: {
        name: 'bonusType',
        value: type,
      },
    });
  };

  const handleWagerTypeChange = (type) => {
    setWagerType(type);
    onChange({
      target: {
        name: 'wagerType',
        value: type,
      },
    });
  };

  const handleNewCheckboxChange = () => {
    onChange({
      target: {
        name: 'isNew',
        value: !entry.isNew,
      },
    });
  };

  const handleDamaCheckboxChange = () => {
    onChange({
      target: {
        name: 'isDama',
        value: !entry.isDama,
      },
    });
  };

  const toggleExtraInfo = () => {
    setShowExtraInfo(!showExtraInfo);
  };

  const handleLogoSelect = (logoUrl) => {
    onChange({
      target: {
        name: 'logo',
        value: logoUrl,
      },
    });
  };

  const handleBackgroundImageSelect = (imageUrl) => {  // Add handler for selecting background image
    onChange({
      target: {
        name: 'backgroundImage',
        value: imageUrl,
      },
    });
  };

  const handleBackgroundSizeChange = (e) => {
    onChange({
      target: {
        name: 'backgroundSize',
        value: e.target.value,
      },
    });
  };

  const handleBackgroundColorChange = (color) => {
    onChange({
      target: {
        name: 'backgroundColor',
        value: color.hex,
      },
    });
  };

  if (!entry) return null;

  return (
    <ModalBackground>
      <ModalContainer>
        <h2>{isNew ? 'Add New Hero Entry' : 'Edit Hero Entry'}</h2>

        <Section>
          <Label>Logo URL</Label>
          <Input type="text" name="logo" placeholder="Logo URL" value={entry.logo} onChange={onChange} />
          <Button onClick={() => setShowLogos(prevState => !prevState)}>Log auswählen</Button>
          {showLogos && (
            <div>
              {logos.map((logo, index) => (
                <img key={index} src={logo} alt="Logo" style={{ width: '50px', height: '50px', margin: '5px', cursor: 'pointer' }} onClick={() => handleLogoSelect(logo)} />
              ))}
            </div>
          )}
        </Section>

        <Section>
          <Label>Title</Label>
          <Input type="text" name="title" placeholder="Title" value={entry.title} onChange={onChange} />
        </Section>

        <Section>
          <Label>Tag</Label>
          <Input type="text" name="tag" placeholder="Tag" value={entry.tag} onChange={onChange} />
        </Section>

        <Section>
          <Label>Link</Label>
          <Input type="text" name="link" placeholder="Link" value={entry.link} onChange={onChange} />
        </Section>

        <Section>
          <Label>Bonus Amount</Label>
          <Input type="number" name="bonusAmount" placeholder="Bonus Amount" value={entry.bonusAmount} onChange={onChange} />
        </Section>

        <Section>
          <Label>Bonus Type</Label>
          <CheckboxContainer>
            <CheckboxLabel>
              <Checkbox
                type="checkbox"
                checked={bonusType === "Sticky"}
                onChange={() => handleBonusTypeChange("Sticky")}
              />
              Sticky
            </CheckboxLabel>
            <CheckboxLabel>
              <Checkbox
                type="checkbox"
                checked={bonusType === "NonSticky"}
                onChange={() => handleBonusTypeChange("NonSticky")}
              />
              Non Sticky
            </CheckboxLabel>
          </CheckboxContainer>
        </Section>

        <Section>
          <Label>Max Bonus</Label>
          <Input type="number" name="maxBonus" placeholder="Max Bonus" value={entry.maxBonus} onChange={onChange} />
        </Section>

        <Section>
          <Label>Max Bet</Label>
          <Input type="number" name="maxBet" placeholder="Max Bet" value={entry.maxBet} onChange={onChange} />
        </Section>

        <Section>
          <Label>Wager</Label>
          <Input type="number" name="wager" placeholder="Wager" value={entry.wager} onChange={onChange} />
        </Section>

        <Section>
          <Label>Wager Type</Label>
          <CheckboxContainer>
            <CheckboxLabel>
              <Checkbox
                type="checkbox"
                checked={wagerType === "NoWager"}
                onChange={() => handleWagerTypeChange("NoWager")}
              />
              No Wager
            </CheckboxLabel>
            <CheckboxLabel>
              <Checkbox
                type="checkbox"
                checked={wagerType === "B"}
                onChange={() => handleWagerTypeChange("B")}
              />
              B
            </CheckboxLabel>
            <CheckboxLabel>
              <Checkbox
                type="checkbox"
                checked={wagerType === "D"}
                onChange={() => handleWagerTypeChange("D")}
              />
              D
            </CheckboxLabel>
            <CheckboxLabel>
              <Checkbox
                type="checkbox"
                checked={wagerType === "D+B"}
                onChange={() => handleWagerTypeChange("D+B")}
              />
              D+B
            </CheckboxLabel>
          </CheckboxContainer>
        </Section>

        <Section>
          <Label>Highlights (comma separated)</Label>
          <TextArea
            name="highlights"
            placeholder="Highlights (comma separated)"
            value={entry.extraInfo.highlights.join(', ')}
            onChange={(e) =>
              onChange({
                target: {
                  name: 'extraInfo',
                  value: { ...entry.extraInfo, highlights: e.target.value.split(', ') },
                },
              })
            }
          />
        </Section>

        <Section>
          <Label>Payment Methods</Label>
          <CheckboxContainer>
            {allPaymentMethods.map(method => (
              <CheckboxLabel key={method}>
                <Checkbox
                    type="checkbox"
                    checked={(entry.extraInfo.paymentMethods || []).map(pm => pm.name).includes(method)}
                    onChange={() => handleCheckboxChange(method)}
                />

                {method}
              </CheckboxLabel>
            ))}
          </CheckboxContainer>
        </Section>

        <Section>
          <Label>Filter Bonus Types</Label>
          <CheckboxContainer>
            {filterBonusTypes.map(type => (
              <CheckboxLabel key={type}>
              <Checkbox
                  type="checkbox"
                  checked={(entry.filterBonusTypes || []).includes(type)}
                  onChange={() => handleFilterBonusTypeChange(type)}
              />

                {type}
              </CheckboxLabel>
            ))}
          </CheckboxContainer>
        </Section>

        <Section>
          <Label>Background Image</Label>
          <Input type="text" name="backgroundImage" placeholder="Background Image" value={entry.backgroundImage} onChange={onChange} />
          <Button onClick={() => setShowBackgroundImages(prevState => !prevState)}>Hintergrundbild auswählen</Button>
          {showBackgroundImages && (
            <div>
              {backgroundImages.map((image, index) => (
                <img key={index} src={image} alt="Background" style={{ width: '50px', height: '50px', margin: '5px', cursor: 'pointer' }} onClick={() => handleBackgroundImageSelect(image)} />
              ))}
            </div>
          )}
        </Section>

        <Section>
          <Label>Background Image Size</Label>
          <select name="backgroundSize" value={entry.backgroundSize} onChange={handleBackgroundSizeChange}>
            <option value="cover">Cover</option>
            <option value="contain">Contain</option>
            <option value="auto">Auto</option>
          </select>
        </Section>

        <Section>
          <Label>Background Color</Label>
          <SketchPicker 
            color={entry.backgroundColor}
            onChangeComplete={handleBackgroundColorChange}
          />
        </Section>

        <Section>
          <Label>Hero Section Title</Label>
          <Input type="text" name="HeroSectionTitle" placeholder="Hero Section Title" value={entry.HeroSectionTitle} onChange={onChange} />
        </Section>

        <Section>
          <Label>Hero Features (comma separated)</Label>
          <TextArea
            name="HeroFeatures"
            placeholder="Hero Features (comma separated)"
            value={entry.HeroFeatures.join(', ')}
            onChange={(e) =>
              onChange({
                target: {
                  name: 'HeroFeatures',
                  value: e.target.value.split(', '),
                },
              })
            }
          />
        </Section>

        <Section>
          <Label>Tags</Label>
          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              checked={entry.isNew}
              onChange={handleNewCheckboxChange}
            />
            Als Neu markieren
          </CheckboxLabel>
          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              checked={entry.isDama}
              onChange={handleDamaCheckboxChange}
            />
            Dama Gruppe
          </CheckboxLabel>
        </Section>

        <Section>
          <h3>Preview</h3>
          <ContainerPreview>
            <HeroContainerPreview
              backgroundImage={entry.backgroundImage}
              backgroundSize={entry.backgroundSize}
              backgroundColor={entry.backgroundColor}
            >
              {entry.logo && <HeroImage src={entry.logo} alt="Casi Logo" />}
              <HeroTitle>{entry.HeroSectionTitle}</HeroTitle>

              {/* Hero Features Row */}
              {entry.HeroFeatures && (
                <HeroFeaturesRow>
                  {entry.HeroFeatures.map((feature, index) => (
                    <HeroFeature key={index}>
                      <CheckIcon src={checkIcon} alt="check" />
                      <FeatureText>{feature}</FeatureText>
                    </HeroFeature>
                  ))}
                </HeroFeaturesRow>
              )}

              <HeroDescription>{entry.description}</HeroDescription>
              <HeroButton onClick={toggleExtraInfo}>
                {showExtraInfo ? "Weniger Informationen" : "Mehr Informationen"}
              </HeroButton>
              {showExtraInfo && (
                <ExtraInfoContainer show={showExtraInfo}>
                  <InfoList>
                    <p className="subtitle">Casino Highlight</p>
                    <ul>
                      {entry.extraInfo.highlights.map((highlight, index) => (
                        <li key={index}>
                          <CheckIcon src={checkIcon} alt="check" />
                          {highlight}
                        </li>
                      ))}
                    </ul>
                  </InfoList>
                  <PaymentTypeIcons>
                    <p className="subtitle">Zahlungsmethoden</p>
                    <div className="icon-row">
                      {entry.extraInfo.paymentMethods.map((method, index) => (
                        <BankingOption key={index} title={method.name}>
                          <img src={iconMap[method.name]} alt={method.name} />
                        </BankingOption>
                      ))}
                    </div>
                  </PaymentTypeIcons>
                </ExtraInfoContainer>
              )}
            </HeroContainerPreview>
          </ContainerPreview>
        </Section>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onSave}>{isNew ? 'Add Entry' : 'Save Changes'}</Button>
          <CancelButton onClick={onClose}>
            Cancel
          </CancelButton>
        </div>
      </ModalContainer>
    </ModalBackground>
  );
};

export default EditHeroCasiModal;
