import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import tmi from 'tmi.js';
import { getToken, getUserInfo } from '../backend/twitchService';

const SERVER_ADDRESS = process.env.REACT_APP_SERVER_ADDRESS;
const TWITCH_USER_ID = process.env.REACT_APP_TWITCH_USER_ID;
const CHANNEL_NAME = process.env.REACT_APP_CHANNEL_NAME;

const Content = styled.div`
  padding: 20px;
  flex-grow: 1;
  background-color: #ecf0f1;
  overflow-y: auto;
  height: 100vh;
`;

const Card = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;
`;

const PlayerList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
`;

const TeamList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const Team = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const PlayerListItem = styled.li`
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  width: 70%;
  border: 1px solid #ddd;
  cursor: pointer;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

const PlayerInfo = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 15px;

    span {
      font-size: 16px;
    }

    .label {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
`;

const PlayerValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 15px;

  span {
    margin-right: 5px;
    font-size: 16px;
  }

  .label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .value {
    font-size: 16px;
  }
`;

const PlayerActions = styled.div`
  display: flex;
  align-items: center;
`;

const ControlPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  margin: 5px;
  padding: 10px;
  font-size: 16px;
`;

const Button = styled.button`
  margin: 5px;
  padding: 10px;
  font-size: 16px;
  background-color: #7724cc;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #772ce8;
  }
  &:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
  }
`;

const SettingsButton = styled.button`
  margin: 5px;
  padding: 10px;
  font-size: 16px;
  background-color: #7724cc;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: 50px;
  &:hover {
    background-color: #772ce8;
  }
`;

const DeleteButton = styled(Button)`
  background-color: #ff4d4d;
  &:hover {
    background-color: #e60000;
  }
  &:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
  }
`;

const ControlPanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const BotControlPanel = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  width: 48%; /* Adjusted to fit two containers side by side */
`;

const PlayerControlPanel = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  width: 48%; /* Adjusted to fit two containers side by side */
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
`;

const TournamentStatus = styled.div`
  font-size: 18px;
  background-color: ${props => (props.$active ? '#b5552c' : props.$registrationOpen ? '#f39c12' : '#c0392b')};
  color: #ecf0f1;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  div {
    margin-bottom: 15px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
`;

const ModalHeader = styled.h2`
  margin-top: 0;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const PrizeContainer = styled.div`
  margin-bottom: 10px;
`;

const PrizeLabel = styled.span`
  display: inline-block;
  width: 50px;
`;

const PrizeInput = styled(Input)`
  width: 80px;
`;

const PrizeSelect = styled.select`
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  font-size: 16px;
`;

const ToggleSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ToggleSwitchLabel = styled.label`
  margin-left: 10px;
`;

const ToggleSwitch = styled.input`
  appearance: none;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;

  &:checked {
    background-color: #4caf50;
  }

  &:before {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
    top: 1px;
    left: 1px;
    transition: transform 0.3s;
  }

  &:checked:before {
    transform: translateX(20px);
  }
`;

// Additional styles for the authentication prompt
const AuthPrompt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px;
  border-radius: 5px;
`;

const AuthStatus = styled.div`
  font-size: 18px;
  background-color: ${props => (props.$authenticated ? '#b5552c' : '#c0392b')};
  color: #ecf0f1;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  margin-bottom: 10px;
  width: fit-content;
  cursor: pointer;  // Cursor ändern
  &:hover {
    opacity: 0.8;
  }
`;

const StreamerView = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated') === 'true');
    const [authDeclined, setAuthDeclined] = useState(localStorage.getItem('authDeclined') === 'true');
    const [token, setToken] = useState(null);
    const [registeredPlayers, setRegisteredPlayers] = useState([]);
    const [client, setClient] = useState(null);
    const [singleTournamentState, setSingleTournamentState] = useState({
        round: 1,
        players: [],
        currentPlayerIndex: 0,
        allRounds: [],
        winners: []
    });
    const [isRegistrationOpen_Single, setIsRegistrationOpen_Single] = useState(false);
    const [isTournamentActive_Single, setisTournamentActive_Single] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [prizes, setPrizes] = useState([]);
    const [prizesDraft, setPrizesDraft] = useState([]);
    const [showPlayerInput, setShowPlayerInput] = useState(false);
    const [newPlayerUsername, setNewPlayerUsername] = useState('');
    const [newPlayerGame, setNewPlayerGame] = useState('');
    const [newPlayerPlatform, setNewPlayerPlatform] = useState('');
    const [autoPoints, setAutoPoints] = useState(false);
    const [viewerCount, setViewerCount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [modalPlayer, setModalPlayer] = useState(null);
    const [modalValue, setModalValue] = useState('');
    const [modalBuyValue, setModalBuyValue] = useState('');
    const [modalGame, setModalGame] = useState('');
    const [botCounter, setBotCounter] = useState(1);
    const [botGame, setBotGame] = useState('');
    const [showBotInput, setShowBotInput] = useState(false);

  const fetchInitialData = async () => {
    try {
      const [playersRes, statusRes, tournamentStateRes] = await Promise.all([
        axios.get(`${SERVER_ADDRESS}/registeredPlayers`),
        axios.get(`${SERVER_ADDRESS}/tournamentStatus`),
        axios.get(`${SERVER_ADDRESS}/singleTournamentState`)
      ]);

      console.log('Registered players:', playersRes.data);
      console.log('Tournament status:', statusRes.data);
      console.log('Single tournament state:', tournamentStateRes.data);

      setRegisteredPlayers(playersRes.data);
      setIsRegistrationOpen_Single(statusRes.data.isRegistrationOpen_Single);
      setisTournamentActive_Single(statusRes.data.isTournamentActive_Single);
      setSingleTournamentState(tournamentStateRes.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    console.log('Registered players updated:', registeredPlayers);
  }, [registeredPlayers]);

  useEffect(() => {
    fetchInitialData();
  }, [isAuthenticated, authDeclined]);

  useEffect(() => {
    if (!isAuthenticated || authDeclined) return;
    
    const fetchTokenAndData = async () => {
      try {
        const token = await getToken();
        console.log('Token fetched:', token);
        setToken(token);
        await fetchInitialData();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchTokenAndData();
  }, [isAuthenticated, authDeclined]);

  useEffect(() => {
    if (!token || authDeclined) return;

    const initializeClient = async () => {
      try {
        const newClient = new tmi.Client({
          options: { debug: true },
          identity: {
            username: CHANNEL_NAME,
            password: `oauth:${token}`
          },
          channels: [CHANNEL_NAME]
        });

        await newClient.connect();

        newClient.on('message', async (channel, tags, message, self) => {
            if (self) return;
    
            const username = tags['display-name'];
    
            if (message.startsWith('!singleturnier')) {
              const args = message.split(' ');
              const playerName = username;
              const game = args.slice(1).join(' ');
    
              try {
                const { data: status } = await axios.get(`${SERVER_ADDRESS}/singleTournamentRegistrationStatus`);
    
                if (!status.isRegistrationOpen) {
                  newClient.say(CHANNEL_NAME, 'Single Turnier Anmeldephase leider geschlossen');
                } else {
                  const response = await axios.post(`${SERVER_ADDRESS}/registerSingleTournament`, {
                    username,
                    playerName,
                    game,
                    platform: 'Twitch'
                  });

                if (response.data.status === 'game_updated') {
                  const updatedPlayer = response.data.player;
                  setRegisteredPlayers((prevPlayers) =>
                    prevPlayers.map((player) =>
                      player.username === updatedPlayer.username ? updatedPlayer : player
                    )
                  );
                } else {
                  console.log(response.data.message);
                  setRegisteredPlayers((prev) => [...prev, response.data.player]);
                }
              }
            } catch (error) {
              console.error('Error registering player:', error.response ? error.response.data : error.message);
            }
          }
        });

        setClient(newClient);

        return () => {
          newClient.disconnect();
        };
      } catch (error) {
        console.error('Error initializing client:', error);
      }
    };

    initializeClient();
  }, [token, authDeclined]);

  const handleOpenRegistration = async () => {
    try {
      const response = await axios.post(`${SERVER_ADDRESS}/openRegistration`, {
        mode: 'singleTournament' // Korrektes Feld
      });
      setIsRegistrationOpen_Single(true);
      client.say(
        CHANNEL_NAME,
        'Single Turnier Anmeldephase gestartet ... Registriere dich jetzt mit !singleturnier <Spielename> um am Turnier teilzunehmen.'
      );
      alert(response.data.message);
    } catch (error) {
      console.error('Error opening registration:', error.response ? error.response.data : error.message);
    }
  };
  

  const handleCloseRegistration = async () => {
    try {
      const response = await axios.post(`${SERVER_ADDRESS}/closeRegistration`, {
        tournamentType: 'singleTournament'
      });
      setIsRegistrationOpen_Single(false);
      alert(response.data.message);
    } catch (error) {
      console.error('Error closing registration:', error.response ? error.response.data : error.message);
    }
  };

  const handleStartSingleTournament = async () => {
    try {
      // Holen Sie die neueste Spielerliste vom Server
      await fetchRegisteredPlayers();
      
      if (registeredPlayers.length < 8) {
        alert(`Nicht genügend Spieler. Aktuell registriert: ${registeredPlayers.length}/8`);
        return;
      }
  
      await handleCloseRegistration();
      const response = await axios.post(`${SERVER_ADDRESS}/startSingleTournament`);
      if (response.data.status === 'success') {
        setSingleTournamentState(response.data.state);
        setisTournamentActive_Single(true);
        alert('Single Turnier gestartet');
      } else {
        alert(response.data.message || 'Fehler beim Starten des Turniers');
      }
    } catch (error) {
      console.error('Fehler beim Starten des Single Turniers:', error);
      alert(error.response?.data?.message || 'Fehler beim Starten des Turniers');
    }
  };


  
  useEffect(() => {
    fetchRegisteredPlayers();
    // Setzen Sie ein Intervall, um die Spielerliste regelmäßig zu aktualisieren
    const interval = setInterval(fetchRegisteredPlayers, 5000);
    return () => clearInterval(interval);
  }, []);
  
  const handleUpdatePlayerValue = async () => {
    if (!modalPlayer) return;
  
    try {
      const response = await axios.post(`${SERVER_ADDRESS}/updateSinglePlayerValue`, {
        username: modalPlayer.username,
        value: modalValue,
        buyValue: modalBuyValue,
        game: modalGame
      });
  
      if (response.data.status === 'success') {
        setSingleTournamentState(prevState => ({
          ...prevState,
          players: prevState.players.map(p => 
            p && p.username === modalPlayer.username 
              ? { ...p, value: modalValue, buyValue: modalBuyValue, game: modalGame } 
              : p
          )
        }));
  
        setShowModal(false);
        alert('Spielerwert aktualisiert');
      } else {
        alert('Fehler beim Aktualisieren des Spielerwerts: ' + response.data.message);
      }
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Spielerwerts:', error);
      alert('Fehler beim Aktualisieren des Spielerwerts. Bitte versuchen Sie es erneut.');
    }
  };

  const handleEditPlayerValue = (player) => {
    setModalPlayer(player);
    setModalValue(player.value || '');
    setModalBuyValue(player.buyValue || '');
    setModalGame(player.game || '');
    setShowModal(true);
  };

  const handleRemovePlayer = async (username) => {
    try {
      const response = await axios.post(`${SERVER_ADDRESS}/removePlayer`, { username });
      setRegisteredPlayers((prev) => prev.filter(player => player.username !== username));
      alert(response.data.message);
    } catch (error) {
      console.error('Fehler beim Entfernen des Spielers:', error.response ? error.response.data : error.message);
    }
  };

  const handleResetTournament = async () => {
    try {
      handleCloseRegistration();
      const response = await axios.post(`${SERVER_ADDRESS}/resetSingleTournament`);
      setRegisteredPlayers([]);
      setSingleTournamentState({
        round: 1,
        players: [],
        currentPlayerIndex: 0,
        allRounds: [],
        winners: []
      });
      setisTournamentActive_Single(false);
      alert(response.data.message);
    } catch (error) {
      console.error('Fehler beim Zurücksetzen des Turniers:', error.response ? error.response.data : error.message);
    }
  };

  const handleTournamentEnd = async () => {
    try {
      const response = await axios.post(`${SERVER_ADDRESS}/endSingleTournament`, { autoPoints, prizes });
      
      if (autoPoints) {
        alert('Punkte wurden automatisch vergeben');
      }

      alert('Turnier wurde beendet');
      setisTournamentActive_Single(false);
      setSingleTournamentState(prevState => ({
        ...prevState,
        winners: response.data.winners
      }));
    } catch (error) {
      console.error('Fehler beim Beenden des Turniers:', error);
    }
  };

  const handleNextRound = async () => {
    try {
      const response = await axios.post(`${SERVER_ADDRESS}/nextSingleRound`);
      setSingleTournamentState(response.data.state);
      alert('Nächste Runde gestartet');
    } catch (error) {
      console.error('Fehler beim Starten der nächsten Runde:', error);
    }
  };

  const handleAuthStatusClick = () => {
    localStorage.removeItem('authDeclined');
    localStorage.removeItem('isAuthenticated');
    setAuthDeclined(false);
    setIsAuthenticated(false);
  };

  const handleYesClick = () => {
    localStorage.setItem('isAuthenticated', 'true');
    setIsAuthenticated(true);
  };

  const handleNoClick = () => {
    localStorage.setItem('authDeclined', 'true');
    setAuthDeclined(true);
  };

  const handleRegisterPlayer = async () => {
    try {
      const response = await axios.post(`${SERVER_ADDRESS}/register`, {
        username: newPlayerUsername,
        playerName: newPlayerUsername,
        game: newPlayerGame,
        platform: newPlayerPlatform,
        isBot: false // oder true, je nachdem, ob es sich um einen Bot handelt
      });
  
      if (response.data.status === 'success' || response.data.status === 'game_updated') {
        setRegisteredPlayers(prev => {
          const existingPlayerIndex = prev.findIndex(p => p.username === response.data.player.username);
          if (existingPlayerIndex !== -1) {
            // Aktualisieren Sie den vorhandenen Spieler
            const updatedPlayers = [...prev];
            updatedPlayers[existingPlayerIndex] = response.data.player;
            return updatedPlayers;
          } else {
            // Fügen Sie den neuen Spieler hinzu
            return [...prev, response.data.player];
          }
        });
        setNewPlayerUsername('');
        setNewPlayerGame('');
        setNewPlayerPlatform('');
        setShowPlayerInput(false);
        alert(`${newPlayerUsername} wurde erfolgreich für das Einzelturnier ${response.data.status === 'game_updated' ? 'aktualisiert' : 'registriert'}.`);
      } else {
        alert('Fehler bei der Registrierung: ' + response.data.message);
      }
    } catch (error) {
      console.error('Fehler beim Registrieren des Spielers:', error.response ? error.response.data : error.message);
      alert('Fehler beim Registrieren des Spielers. Bitte versuchen Sie es erneut.');
    }
  };

  const handleRegisterBot = async () => {
    const botName = `THFS_Bot_${botCounter}`;
    try {
      const response = await axios.post(`${SERVER_ADDRESS}/register`, {
        username: botName,
        playerName: botName,
        game: botGame,
        platform: 'Bot',
        isBot: true,
        tournamentType: 'singleTournament'
      });
      
      setRegisteredPlayers((prev) => [...prev, response.data.player]);
      setBotCounter(prev => prev + 1);
      setBotGame('');
      setShowBotInput(false);
      alert(`${botName} für das Einzelturnier registriert`);
    } catch (error) {
      console.error('Fehler beim Registrieren des Bots:', error.response ? error.response.data : error.message);
    }
  };
  
  const fetchRegisteredPlayers = async () => {
    try {
      const response = await axios.get(`${SERVER_ADDRESS}/registeredPlayers`, {
        params: { tournamentType: 'singleTournament' }
      });
      console.log('Fetched Single Tournament players:', response.data);
      setRegisteredPlayers(response.data);
    } catch (error) {
      console.error('Error fetching Single Tournament players:', error);
    }
  };

  const handleAddBot = () => {
    setShowBotInput(true);
  };



  const renderSingleTournament = () => (
    <Card>
      <AuthStatus $authenticated={isAuthenticated} onClick={handleAuthStatusClick}>
        {isAuthenticated ? 'Twitch Authenticated' : 'Not Authenticated'}
      </AuthStatus>
      <h3>Single Turnier</h3>
      <ControlPanel>
        <ButtonContainer>
          <SettingsButton onClick={handleOpenSettings}>Einstellungen</SettingsButton>
          <TournamentSettings autoPoints={autoPoints} prizes={prizes} />
        </ButtonContainer>
        <ButtonContainer>
          <div>
            <Button
              onClick={handleOpenRegistration}
              disabled={isTournamentActive_Single || isRegistrationOpen_Single}
            >
              Anmeldephase starten
            </Button>
            <Button
              onClick={handleCloseRegistration}
              disabled={isTournamentActive_Single || !isRegistrationOpen_Single}
            >
              Anmeldephase beenden
            </Button>
            <Button
              onClick={handleStartSingleTournament}
              disabled={isTournamentActive_Single || registeredPlayers.length < 8}
            >
              Turnier starten ({registeredPlayers.length}/8 Spieler)
            </Button>
            <Button 
              onClick={handleNextRound}
              disabled={!isTournamentActive_Single}
            >
              Nächste Runde
            </Button>
          </div>
  
          <Button
            onClick={handleTournamentEnd}
            disabled={!isTournamentActive_Single}
          >
            Turnier beenden
          </Button>
          <Button onClick={handleResetTournament}>Turnier zurücksetzen</Button>
        </ButtonContainer>
      </ControlPanel>
      
      <h2>{isTournamentActive_Single ? 'Spieler im Turnier' : 'Registrierte Spieler'}</h2>
      <PlayerList>
        {(isTournamentActive_Single ? singleTournamentState.players : registeredPlayers).map((player, index) => (
          player && (
            <PlayerListItem
              key={player.username}
              onClick={() => handleEditPlayerValue(player)}
            >
              <ProfileImage src={player.profileImageUrl} alt={player.username} />
              <PlayerInfo>
                <div>
                  <span className="label">Spielername:</span>
                  <span>{player.username}</span>
                </div>
                <div>
                  <span className="label">Game Name:</span>
                  <span>{player.game}</span>
                </div>
                <div>
                  <span className="label">Plattform:</span>
                  <span>{player.platform}</span>
                </div>
                <PlayerValueContainer>
                  <span className="label">Endergebnis:</span>
                  <span className="value">{player.value} €</span>
                </PlayerValueContainer>
              </PlayerInfo>
              <PlayerActions>
                <Button onClick={() => handleEditPlayerValue(player)}>Bearbeiten</Button>
                <DeleteButton onClick={() => handleRemovePlayer(player.username)}>Entfernen</DeleteButton>
              </PlayerActions>
            </PlayerListItem>
          )
        ))}
      </PlayerList>
      
      <TournamentStatus
        $active={isTournamentActive_Single}
        $registrationOpen={isRegistrationOpen_Single}
      >
        {isTournamentActive_Single
          ? 'Turnier in Gange'
          : isRegistrationOpen_Single
            ? 'Anmeldephase läuft'
            : 'Beendet'}
      </TournamentStatus>
  
      {showModal && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Spielerwert aktualisieren für {modalPlayer?.username}</ModalHeader>
            <div>
              <label>Endergebnis:</label>
              <Input
                type="number"
                step="0.01"
                value={modalValue}
                onChange={(e) => setModalValue(e.target.value)}
                placeholder="Endergebnis"
              />
            </div>
            <div>
              <label>Einkaufswert:</label>
              <Input
                type="number"
                step="0.01"
                value={modalBuyValue}
                onChange={(e) => setModalBuyValue(e.target.value)}
                placeholder="Einkaufswert"
              />
            </div>
            <div>
              <label>Spiel:</label>
              <Input
                type="text"
                value={modalGame}
                onChange={(e) => setModalGame(e.target.value)}
                placeholder="Spiel"
              />
              </div>
            <ModalFooter>
              <Button onClick={handleUpdatePlayerValue}>Speichern</Button>
              <Button onClick={() => setShowModal(false)}>Abbrechen</Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      )}
    </Card>
  );

  const handleOpenSettings = () => {
    setPrizesDraft([...prizes]);
    setShowSettings(true);
  };

  const handleCloseSettings = () => {
    setShowSettings(false);
  };

  const handleSaveSettings = async () => {
    try {
      const response = await axios.post(`${SERVER_ADDRESS}/saveTournamentSettings`, {
        prizes: prizesDraft,
        autoPoints
      });

      if (response.data.status === 'success') {
        setPrizes(prizesDraft);
        localStorage.setItem('prizes', JSON.stringify(prizesDraft));
        setShowSettings(false);
        alert('Settings saved');
      } else {
        alert('Error saving settings');
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      alert('Error saving settings');
    }
  };

  const handleAddPrize = () => {
    setPrizesDraft(prevPrizes => [
      ...prevPrizes,
      { id: prevPrizes.length + 1, value: '', currency: 'EUR', location: '' }
    ]);
  };

  const handleRemovePrize = (id) => {
    setPrizesDraft(prevPrizes => prevPrizes.filter(prize => prize.id !== id));
  };

  const handlePrizeChange = (id, field, value) => {
    setPrizesDraft(prevPrizes => prevPrizes.map(prize =>
      prize.id === id ? { ...prize, [field]: value } : prize
    ));
  };

  const renderPrizeFields = () => {
    return prizesDraft.map((prize, index) => (
      <PrizeContainer key={prize.id}>
        <PrizeLabel>Platz {index + 1}:</PrizeLabel>
        <PrizeInput
          type="text"
          value={prize.value}
          onChange={(e) => handlePrizeChange(prize.id, 'value', e.target.value)}
          placeholder="Preisgeld"
        />
        <PrizeSelect
          value={prize.currency}
          onChange={(e) => handlePrizeChange(prize.id, 'currency', e.target.value)}
        >
          <option value="EUR">EUR</option>
          <option value="Punkte">Punkte</option>
        </PrizeSelect>
        <PrizeInput
          type="text"
          value={prize.location}
          onChange={(e) => handlePrizeChange(prize.id, 'location', e.target.value)}
          placeholder="Ausschüttungsort"
        />
        <DeleteButton onClick={() => handleRemovePrize(prize.id)}>Entfernen</DeleteButton>
      </PrizeContainer>
    ));
  };

  const TournamentSettings = ({ autoPoints, prizes }) => {
    return (
      <div style={{ marginLeft: '20px' }}>
        <h3>Aktuelle Turniereinstellungen</h3>
        <ul>
          <li>Automatische Punktevergabe: {autoPoints ? 'Aktiv' : 'Inaktiv'}</li>
          {prizes.map((prize, index) => (
            <li key={index}>
              Platz {index + 1}: {prize.value} {prize.currency} ({prize.location})
            </li>
          ))}
        </ul>
      </div>
    );
  };

  useEffect(() => {
    const savedPrizes = JSON.parse(localStorage.getItem('prizes')) || [];
    setPrizes(savedPrizes);
  }, []);

  useEffect(() => {
    localStorage.setItem('prizes', JSON.stringify(prizes));
  }, [prizes]);

  if (!isAuthenticated && !authDeclined) {
    return (
      <Content>
        <AuthPrompt>
          <h1>Streamer View</h1>
          <p>Do you want to authenticate with Twitch?</p>
          <Button onClick={handleYesClick}>Yes</Button>
          <Button onClick={handleNoClick}>No</Button>
        </AuthPrompt>
      </Content>
    );
  }

  return (
    <Content>
      {!isAuthenticated && !authDeclined ? (
        <AuthPrompt>
          <h1>Streamer View</h1>
          <p>Do you want to authenticate with Twitch?</p>
          <Button onClick={handleYesClick}>Yes</Button>
          <Button onClick={handleNoClick}>No</Button>
        </AuthPrompt>
      ) : (
        <Card>
          <AuthStatus $authenticated={isAuthenticated} onClick={handleAuthStatusClick}>
            {isAuthenticated ? 'Twitch Authenticated' : 'Not Authenticated'}
          </AuthStatus>
          <h3>Single Tournament</h3>
          <ControlPanel>
            <ButtonContainer>
              <SettingsButton onClick={handleOpenSettings}>Einstellungen</SettingsButton>
              <TournamentSettings autoPoints={autoPoints} prizes={prizes} />
            </ButtonContainer>
            <ButtonContainer>
              <div>
                <Button
                  onClick={handleOpenRegistration}
                  disabled={isTournamentActive_Single || isRegistrationOpen_Single}
                >
                  Anmeldephase starten
                </Button>
                <Button
                  onClick={handleCloseRegistration}
                  disabled={isTournamentActive_Single || !isRegistrationOpen_Single}
                >
                  Anmeldephase beenden
                </Button>
                <Button
                  onClick={handleStartSingleTournament}
                  disabled={isTournamentActive_Single || registeredPlayers.length < 2}
                >
                  Turnier starten
                </Button>
                <Button 
                  onClick={handleNextRound}
                  disabled={!isTournamentActive_Single}
                >
                  Nächste Runde
                </Button>
              </div>
              <Button
                onClick={handleTournamentEnd}
                disabled={!isTournamentActive_Single}
              >
                Turnier beenden
              </Button>
              <Button onClick={handleResetTournament}>Turnier zurücksetzen</Button>
            </ButtonContainer>
          </ControlPanel>
          
          <h2>{isTournamentActive_Single ? 'Spieler im Turnier' : 'Registrierte Spieler'}</h2>
          <PlayerList>
            {(isTournamentActive_Single ? singleTournamentState.players : registeredPlayers)
              .filter(player => player !== null)
              .map((player, index) => (
              <PlayerListItem
                key={player.username}
                onClick={() => handleEditPlayerValue(player)}
              >
                <ProfileImage src={player.profileImageUrl || '/assets/default.png'} alt={player.username}/>

                <PlayerInfo>
                  <div>
                    <span className="label">Spielername:</span>
                    <span>{player.username}</span>
                  </div>
                  <div>
                    <span className="label">Game Name:</span>
                    <span>{player.game}</span>
                  </div>
                  <div>
                    <span className="label">Plattform:</span>
                    <span>{player.platform}</span>
                  </div>
                  <PlayerValueContainer>
                    <span className="label">Endergebnis:</span>
                    <span className="value">{player.value} €</span>
                  </PlayerValueContainer>
                  {player.buyValue && (
                    <PlayerValueContainer>
                      <span className="label">Einkaufswert:</span>
                      <span className="value">{player.buyValue} €</span>
                    </PlayerValueContainer>
                  )}
                  {player.value && player.buyValue && (
                    <PlayerValueContainer>
                      <span className="label">X-Faktor:</span>
                      <span className="value">
                        {(parseFloat(player.value) / parseFloat(player.buyValue)).toFixed(2)}x
                      </span>
                    </PlayerValueContainer>
                  )}
                </PlayerInfo>
                <PlayerActions>
                  <Button onClick={() => handleEditPlayerValue(player)}>Bearbeiten</Button>
                  {!isTournamentActive_Single && (
                    <DeleteButton onClick={() => handleRemovePlayer(player.username)}>Entfernen</DeleteButton>
                  )}
                </PlayerActions>
              </PlayerListItem>
            ))}
          </PlayerList>
          
          <ControlPanelContainer>
          <BotControlPanel>
                <h2>Bot registrieren</h2>
                {!showBotInput && <Button
                onClick={handleAddBot}
                disabled={isTournamentActive_Single}
                >Bot hinzufügen</Button>}
                {showBotInput && (
                <>
                    <p>Nächster Bot-Name: THFS_Bot_{botCounter}</p>
                    <Input type="text" value={botGame} onChange={(e) => setBotGame(e.target.value)} placeholder="Spiel" />
                    <Button onClick={handleRegisterBot}>Bot registrieren</Button>
                    <Button onClick={() => setShowBotInput(false)}>Abbrechen</Button>
                </>
                )}
            </BotControlPanel>
            <PlayerControlPanel>
              <h2>Spieler hinzufügen</h2>
              {!showPlayerInput && (
                <Button
                  onClick={() => setShowPlayerInput(true)}
                  disabled={isTournamentActive_Single}
                >
                  Spieler hinzufügen
                </Button>
              )}
              {showPlayerInput && (
                <>
                  <Input 
                    type="text" 
                    value={newPlayerUsername} 
                    onChange={(e) => setNewPlayerUsername(e.target.value)} 
                    placeholder="Benutzername" 
                  />
                  <Input 
                    type="text" 
                    value={newPlayerGame} 
                    onChange={(e) => setNewPlayerGame(e.target.value)} 
                    placeholder="Spiel" 
                  />
                  <Input 
                    type="text" 
                    value={newPlayerPlatform} 
                    onChange={(e) => setNewPlayerPlatform(e.target.value)} 
                    placeholder="Plattform" 
                  />
                  <Button onClick={handleRegisterPlayer}>Spieler registrieren</Button>
                  <Button onClick={() => setShowPlayerInput(false)}>Abbrechen</Button>
                </>
              )}
            </PlayerControlPanel>
          </ControlPanelContainer>
          
          <TournamentStatus
            $active={isTournamentActive_Single}
            $registrationOpen={isRegistrationOpen_Single}
          >
            {isTournamentActive_Single
              ? 'Turnier in Gange'
              : isRegistrationOpen_Single
                ? 'Anmeldephase läuft'
                : 'Beendet'}
          </TournamentStatus>
  
          {showModal && (
            <ModalOverlay>
              <ModalContent>
                <ModalHeader>Spielerwert aktualisieren für {modalPlayer?.username}</ModalHeader>
                <div>
                  <label>Endergebnis:</label>
                  <Input
                    type="number"
                    step="0.01"
                    value={modalValue}
                    onChange={(e) => setModalValue(e.target.value)}
                    placeholder="Endergebnis"
                  />
                </div>
                <div>
                  <label>Einkaufswert:</label>
                  <Input
                    type="number"
                    step="0.01"
                    value={modalBuyValue}
                    onChange={(e) => setModalBuyValue(e.target.value)}
                    placeholder="Einkaufswert"
                  />
                </div>
                <div>
                  <label>Spiel:</label>
                  <Input
                    type="text"
                    value={modalGame}
                    onChange={(e) => setModalGame(e.target.value)}
                    placeholder="Spiel"
                  />
                </div>
                <ModalFooter>
                  <Button onClick={handleUpdatePlayerValue}>Speichern</Button>
                  <Button onClick={() => setShowModal(false)}>Abbrechen</Button>
                </ModalFooter>
              </ModalContent>
            </ModalOverlay>
          )}
        </Card>
      )}
  
      {showSettings && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Turniereinstellungen</ModalHeader>
            <ToggleSwitchContainer>
              <ToggleSwitch
                type="checkbox"
                checked={autoPoints}
                onChange={() => setAutoPoints(!autoPoints)}
                id="autoPoints"
              />
              <ToggleSwitchLabel htmlFor="autoPoints">Automatische Punktevergabe</ToggleSwitchLabel>
            </ToggleSwitchContainer>
            {renderPrizeFields()}
            <Button onClick={handleAddPrize}>Preis hinzufügen</Button>
            <ModalFooter>
              <Button onClick={handleSaveSettings}>Speichern</Button>
              <Button onClick={handleCloseSettings}>Abbrechen</Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      )}
    </Content>
  );
};

export default StreamerView;