// Dashboard.js
import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Paper, Typography, TextField, Card, CardContent, CardHeader, Divider, Avatar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation, Link, Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { RiHome4Line, RiCrosshair2Line } from "react-icons/ri";
import { MdOutlineCasino, MdQueryStats } from "react-icons/md";
import { GiCrossedSwords } from "react-icons/gi";
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import StreamerView from './StreamerView';
import moment from 'moment';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: open ? `${drawerWidth}px` : 0,
      width: `calc(100% - ${open ? drawerWidth : 0}px)`,
    },
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up('sm')]: {
    width: `calc(100% - ${open ? drawerWidth : 0}px)`,
    marginLeft: open ? `${drawerWidth}px` : 0,
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: '0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

const API_URL = process.env.REACT_APP_API_URL;

const Dashboard = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [viewStats, setViewStats] = useState([]);
  const [dateRangeStart, setDateRangeStart] = useState('');
  const [dateRangeEnd, setDateRangeEnd] = useState('');

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = () => {
    const startDate = dateRangeStart ? moment(dateRangeStart).format('DD-MM-YYYY') : null;
    const endDate = dateRangeEnd ? moment(dateRangeEnd).format('DD-MM-YYYY') : null;

    const url = startDate && endDate
      ? `${API_URL}/api/stats/views/range?startDate=${startDate}&endDate=${endDate}`
      : `${API_URL}/api/stats/views`;

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setViewStats(data))
      .catch(error => console.error('Error fetching view stats:', error));
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const handleSendMessage = async () => {
    try {
      const response = await fetch(`${API_URL}/api/send-message-to-extension`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ message: 'Hello from the dashboard!' })
      });

      if (response.ok) {
        console.log('Message sent to extension');
      } else {
        console.error('Failed to send message to extension');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const menuItems = [
    { text: 'Home', icon: <RiHome4Line size={24} />, path: '/dashboard' },
    { text: 'Bonus Hunt Tipspiel', icon: <RiCrosshair2Line size={24} />, path: '/dashboard/manage-games' },
    { text: 'Casi Bonus Einträge', icon: <MdOutlineCasino size={24} />, path: '/dashboard/manage-entries' },
    { text: 'Click Stats', icon: <MdQueryStats size={24} />, path: '/dashboard/click-stats' },
    { text: 'Team Duell', icon: <GiCrossedSwords size={24} />, path: '/dashboard/streamer' },
    { text: 'Single Duell', icon: <GiCrossedSwords size={24} />, path: '/dashboard/streamer_single' },

  ];

  const handleDateRangeChange = (event) => {
    const { name, value } = event.target;
    if (name === 'start') {
      setDateRangeStart(value);
    } else if (name === 'end') {
      setDateRangeEnd(value);
    }
  };

  const handleFilterClick = () => {
    const startDate = dateRangeStart ? moment(dateRangeStart).format('DD-MM-YYYY') : null;
    const endDate = dateRangeEnd ? moment(dateRangeEnd).format('DD-MM-YYYY') : null;

    if (startDate && endDate) {
      const url = `${API_URL}/api/stats/views/range?startDate=${startDate}&endDate=${endDate}`;

      console.log('Fetching data from:', url);

      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setViewStats(data);
          console.log('Filtered data:', data); // Log filtered data to check what's returned
        })
        .catch(error => console.error('Error fetching filtered view stats:', error));
    } else {
      console.error('Invalid date range:', startDate, endDate);
    }
  };

  const handleMenuItemClick = () => {
    setOpen(false);
  };

  const DashboardContent = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={0} sx={{ p: 3, backgroundColor: theme.palette.primary.main, color: 'white' }}>
          <Typography variant="h4" gutterBottom>
            Welcome back, Admin!
          </Typography>
          <Typography variant="body1">
            Here's an overview of your To High For Slots.
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <StyledCard>
          <CardHeader title="Casino Bonus Seitenaufrufe" />
          <Divider />
          <CardContent>
            <Typography variant="h5" component="div" color="textSecondary" sx={{ mb: 2 }}>
              Anzahl der Aufrufe
            </Typography>
            <Typography variant="h4" component="div" color="primary">
              {viewStats.reduce((total, stat) => total + stat.count, 0)}
            </Typography>
            <Box mt={2}>
              <Typography variant="subtitle2" gutterBottom>
                Datumsbereich filtern:
              </Typography>
              <TextField
                name="start"
                type="date"
                variant="outlined"
                size="small"
                value={dateRangeStart}
                onChange={handleDateRangeChange}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ mr: 1 }}
              />
              <TextField
                name="end"
                type="date"
                variant="outlined"
                size="small"
                value={dateRangeEnd}
                onChange={handleDateRangeChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button variant="contained" onClick={handleFilterClick} sx={{ mt: 1 }}>
                Filtern
              </Button>
            </Box>
          </CardContent>
        </StyledCard>
      </Grid>
      <Grid item xs={12} md={6} lg={8}>
        <StyledCard>
          <CardHeader title="Views Trend" />
          <Divider />
          <CardContent>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={viewStats}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </StyledCard>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSendMessage}>
          Send Message to Extension
        </Button>
      </Grid>
    </Grid>
  );

  const drawer = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <DrawerHeader>
        <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
          Dashboard
        </Typography>
        {isMobile && (
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        )}
      </DrawerHeader>
      <List sx={{ flexGrow: 1 }}>
        {menuItems.map((item, index) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              component={Link}
              to={item.path}
              selected={location.pathname === item.path}
              onClick={handleMenuItemClick}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box sx={{ p: 2 }}>
        <Button
          variant="outlined"
          startIcon={<LogoutIcon />}
          onClick={handleLogout}
          fullWidth
        >
          Logout
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} color="default" elevation={1}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            To High For Slots
          </Typography>
          <Avatar sx={{ bgcolor: theme.palette.secondary.main }}>A</Avatar>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant={isMobile ? "temporary" : "persistent"}
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              borderRight: 'none',
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Main open={open}>
        <DrawerHeader />
        {location.pathname === '/dashboard' ? (
          <DashboardContent />
        ) : location.pathname === '/dashboard/streamer' ? (
          <StreamerView />
        ) : (
          <Outlet />
        )}
      </Main>
    </Box>
  );
};

export default Dashboard;
