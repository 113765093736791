import React from 'react';
import { 
  Container, 
  Typography, 
  Paper, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText,
  Grid,
  Box,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/system';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import StarIcon from '@mui/icons-material/Star';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(4, 0),
  backgroundColor: '#f8f9fa',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const HeaderTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.75rem',
  },
}));

const SubheaderTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(4),
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const AboutUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="lg">
      <StyledPaper elevation={3}>
        <HeaderTypography 
          variant={isMobile ? "h4" : "h3"} 
          component="h1" 
          gutterBottom
          style={{ wordBreak: 'break-word' }}
        >
          ToHighForSlots: Your Top Destination for Casino Bonuses and Slot Experiences
        </HeaderTypography>
        
        <StyledTypography variant="body1" paragraph>
          Welcome to ToHighForSlots, your trusted source for premium online casino bonuses and a vibrant slot community. Our platform offers you a comprehensive overview of the best deals and opportunities in the world of online gambling.
        </StyledTypography>

        <SubheaderTypography variant={isMobile ? "h5" : "h4"} gutterBottom>
          Why ToHighForSlots Should Be Your First Choice
        </SubheaderTypography>

        <List>
          {[
            "Exclusive Casino Bonuses: We present you with a carefully curated selection of lucrative bonus offers from leading online casinos. From welcome bonuses to free spins – you'll always find the most up-to-date and attractive promotions here.",
            "Diverse Slot Selection: Immerse yourself in the fascinating world of online slots. Our team regularly tests new games and shares in-depth reviews so you're always informed about the latest trends and best winning opportunities.",
            "Active Community: Join our lively community of slot enthusiasts. Exchange experiences, share strategies, and find like-minded individuals in our forums and chat rooms.",
            "Trusted Information: Our experts conduct thorough research to ensure you always have access to accurate and current information about online casinos, bonus terms, and payout rates.",
            "Responsible Gaming: We place great emphasis on responsible gambling. Find helpful resources and tips here to keep your gaming habits in check.",
            "Mobile Optimization: Enjoy our platform anytime, anywhere – perfectly optimized for your smartphone or tablet.",
            "Transparency and Fairness: We only work with reputable and licensed online casinos to guarantee you a safe and fair gaming experience."
          ].map((text, index) => (
            <ListItem key={index} alignItems="flex-start">
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>

        <Box my={4}>
          <SubheaderTypography variant={isMobile ? "h5" : "h4"} gutterBottom>
            Discover the Benefits of ToHighForSlots
          </SubheaderTypography>
          
          <Grid container spacing={2}>
            {[
              "Daily Updates: Stay up to date with our daily news on bonus offers and slot releases.",
              "Exclusive Deals: Take advantage of special bonus offers we’ve negotiated for our community.",
              "Detailed Reviews: Read our in-depth reviews of online casinos and slot games before making your decision."
            ].map((text, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <ListItem>
                  <ListItemIcon>
                    <StarIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              </Grid>
            ))}
          </Grid>
        </Box>

        <StyledTypography variant="body1" paragraph>
          Make ToHighForSlots your trusted companion in the world of online casinos and slots. Join us today and see for yourself why our community is the first choice for casino enthusiasts. Dive into a world full of excitement, entertainment, and lucrative opportunities – all in one place that provides you with the best conditions for a successful gaming experience.
        </StyledTypography>
      </StyledPaper>
    </Container>
  );
};

export default AboutUs;
